import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Services from "../../../../services/Services";
import HeaderComponent from "../../../template/Header";
import RoutesProfile from "../../ProfilePage/route";
import ReactLoading from 'react-loading';

export default class MyBookings extends Component {
  constructor(props){
    super(props)
        this.state = {
            bookingsList:[],
            deleteId:'',
            isLoading:true,
            isshow:false
        }
    }
  
    componentDidMount = () => {
        this.bookings()
    }

    bookings = () => {
        Services.bookings({user_id:localStorage.getItem('userId')}).then(res =>{
            this.setState({ bookingsList : res.userData})
            if (res.userData.length > 0) {
                setTimeout(() => {
                    this.setState({isLoading : false , isshow: true,})
                  }, 200);
                }
        }).catch(err => {
            console.log(err);
        })
    }

       render() {
    return (
      <>
        <HeaderComponent />

        <HelmetProvider>
          <Helmet>
            <title>Bookings</title>
          </Helmet>
        </HelmetProvider>

        <div className="container mt-5">
          <div className="sec_title text-center">
            <h4>My Bookings</h4>
          </div>
          <div className="sec_subtitle">
            <p></p>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <RoutesProfile />
            </div>
            <div className="col-12">
              <div className="shadow p-3 mb-5 bg-body rounded">
                {
                  (this.state.bookingsList) && <>
                    {
                      (this.state.isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : (
                        <table className="table table-striped">
                          <thead>
                            <tr className="text-center">
                              <th scope="col">No</th>
                              <th scope="col">Service Name</th>
                              <th scope="col">Service Provider Name</th>
                              <th scope="col">Service Price</th>
                              <th scope="col">Status</th>
                            
                            </tr>
                          </thead>
                          <tbody>
                                {this.state.bookingsList.map((data,i) => {
                                    return (
                                        <tr className="text-center" key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>{data.service_id.serviceName}</td>
                                            <td>{data.provider_id.name}</td>
                                            <td>{data.service_id.price}</td>
                                            <td>
                                              {(data.once_complete === false) ? (<h5 className="text-primary">Pending</h5>) : 
                                                <>{(data.verify === true) ? (<><h5 className="text-success">Apoorve</h5></>):(<><h5 className="text-danger">Reject</h5></>)} </>}
                                            </td>
                                        </tr>
                                )})}
                          </tbody>
                        </table>
                      )
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.isshow && <Footer />} */}
      </>
    );
  }
}
