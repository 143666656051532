import React, { Component } from "react";
import "../../../assets/css/style.css";
import "../../../assets/styles/responsive.css";
import { Link, withRouter } from "react-router-dom";
import Services from "../../../services/Services";
import logocar from "../../../assets/images/logocar.png"
import wheel from "../../../assets/images/wheel.png"

class ProviderSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: ''
        }
    }

    componentDidMount() {
        this.callingAPI()
    }

    callingAPI = () => {
        try {
            let { id, verify } = this.props.match.params
            Services.VerifyServiceAPI({ id: id, verify: verify, once_complete: true })
            .then((response) => {
                this.setState({
                    message: response.message
                })
            })

        } catch (error) {
            console.log('Error : ', error);
        }
    }

    render() {
        return (
            <body className="min-vh-100 d-flex flex-column coming_main">
                <div className="coming_page">
                    <header>
                        <div className="container">
                            <nav className="navbar navbar-dark bg-transparenet">
                                <span className="navbar-text ml-auto d-sm-inline-block">
                                    +91 99090 16746
                                </span>
                                <span className="navbar-text d-none d-sm-inline-block"></span>
                            </nav>
                        </div>
                    </header>
                    <main className="my-auto">
                        <div className="container text-center">
                            <Link className="navbar-brand">
                                <img src={logocar} alt="logo" className="car" />
                                <img src={wheel} alt="wheel" className="wheel" />
                            </Link>
                            <h1 className="page-title ">
                                {this.state.message}
                            </h1>
                        </div>
                    </main>
                </div>
            </body>
        );
    }
}

export default withRouter(ProviderSuccess);