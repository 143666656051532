import Call from "./Call";

const Usercars = async (id) => {
    let d = await Call({
      path: "api/admin/usercars",
      method: "POST",
      data: {id:id}
    });
    return d;
  };

  const editCars = async (id) => {
    let d = await Call({
      path: "api/admin/editCars",
      method: "POST",
      data: {id:id}
    });
    return d;
  };

  const addCars = async (data) => {
    let d = await Call({
      path: "api/addCar",
      method: "POST",
      enctype:"multipart/form-data",
      data,
    });
    return d;
  };

const carUpdate = async (data) => {
    let d = await Call({
      path: "api/admin/carUpdate",
      method: "POST",
      data,
    });
    return d;
  };

  const deleteCars = async (id) => {
    let d = await Call({
      path: "api/admin/carDeleted",
      method: "POST",
      data: {_id:id}
    });
    return d;
  };

  const exportObject = {Usercars,carUpdate,addCars,editCars,deleteCars}

export default exportObject