import React, { Component } from 'react'
import HeaderComponent from "../../template/Header";
import AddTocartService from '../../../services/AddTocart'
import "./index.css"
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import swalAlert from 'sweetalert';
import AuthService from "../../../services/auth"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactLoading from 'react-loading';

export class Cart extends Component {

  constructor(props) {
    super(props)
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.ConformOrder = this.ConformOrder.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      userData: [],
      message: false,
      Total_price: '',
      Discount: '',
      orderAmount: '',
      payment_method: 'Cash on delivery',
      couponId: "",
      userId:"",
      cartList: [],
      couponsList: [],
      selectCouponData: [],
      isLoading:true,
      isshow: false,
    }
  }

  componentDidMount() {
    this.getuser()
    this.cartList()
  }

  getuser(){
    AuthService.login({phone: localStorage.getItem("phone")})
    .then(response =>{
        this.setState({userData:response.users,userId:response.users._id})
    })
}

  cartList() {
    AddTocartService.CartList({ userId: localStorage.getItem("userId") })
      .then(response => {
        const initialValue = 0
        const Total_price = response.cartList.reduce((accumulator, current) => accumulator + (current.price - (current.price * current.productId.discount)/100), initialValue)

        const Cart_count = response.cartList.length

          this.setState ({
                countShopping:localStorage.setItem("Count",Cart_count),
                cartList : response.cartList,
                couponsList: response.couponsList,
                Total_price: Total_price
          })
          
          if (response.cartList.length > 0) {
              setTimeout(() => {
                this.setState({isLoading : false , isshow: true,})
              }, 400);
          }
       
      })
  }

  selectCoupon(data) {
    if (this.state.Total_price >= data.minAmount) {
      this.setState({ selectCouponData: data })
    } else {
      toast.error("Check tha minAmount  " + data.minAmount + "  and used this coupons")
    }
  }

  increment(i, data) {
    let { cartList } = this.state;
    let temp = cartList;
    let quantityTotal = temp[i].quantity + 1
    let totalPrice = quantityTotal * data.productId.price

    let formData = new FormData();
    formData.append("id", data._id);
    formData.append("userId", data.userId);
    formData.append("productId", data.productId._id);
    formData.append("quantity", quantityTotal);
    formData.append("price", totalPrice);
    AddTocartService.UpdateCart(formData)
      .then(response => {
        this.cartList()
      })
  }

  decrement(i, data) {
    if (data.quantity === 1) {
      return
   }

    let { cartList } = this.state;
    let temp = cartList;
    let quantityTotal = temp[i].quantity + -1
    let totalPrice = quantityTotal * data.productId.price

    let formData = new FormData();
    formData.append("id", data._id);
    formData.append("userId", data.userId);
    formData.append("productId", data.productId._id);
    formData.append("quantity", quantityTotal);
    formData.append("price", totalPrice);
    AddTocartService.UpdateCart(formData)
      .then(response => {
        this.cartList()
      })
  }

  removeCart(id) {
    AddTocartService.removeCart({ id: id })
      .then(response => {
        this.cartList()
        toast.success(response.message)
      })
  }

  ApplyCoupon(value) {
    let formData = new FormData();
    formData.append("couponId", value._id);
    formData.append("userId", this.state.userId);
    formData.append("coupon_code", value.coupon_code);
    formData.append("total", this.state.Total_price);
    AddTocartService.couponApplay(formData)
      .then(response => {
        if (response.status === true) {
          this.setState({ couponId: response.couponId, Discount: response.descount, orderAmount: response.totalValue })
          swalAlert({
            icon: 'success',
            button: true,
            title: (response.message),
            showConfirmButton: false,
            timer: 3000,
          })
        } else {
          swalAlert({
            icon: 'error',
            button: true,
            title: (response.message),
            showConfirmButton: false,
            timer: 3000,
          })
        }
      })
  }

  handleSubmit(e){
    e.preventDefault()
    const fmData = new FormData();
    fmData.append("id", this.state.userData._id);
    fmData.append("name", this.name.value);
    fmData.append("phone", this.phone.value);
    fmData.append("block", this.block.value);
    fmData.append("houseno", this.houseno.value);
    fmData.append("address", this.address.value);
    AuthService.userUpdate(fmData)
      .then(response => {
        if (response.status === true) {
          this.getuser()
          this.ConformOrder()
        }
      })
  }

  ConformOrder() {
    let { cartList } = this.state;
    let orderListData = []
    cartList.map(data => {
      return orderListData.push({ productId: data.productId._id, productName: data.productId.name, productImage: data.productId.image, quantity: data.quantity, price: data.price })
    })

    let data = {
      userId:this.state.userId,
      payment_method:this.state.payment_method,
      orderAmount:this.state.orderAmount ? this.state.orderAmount : this.state.Total_price,
      orderList:orderListData
    }
    if (this.state.couponId) {
      data.couponId = this.state.couponId
    }
    AddTocartService.MakePurchase(data)
      .then(response => {
        if (response.status === true) {
          localStorage.removeItem("Count")
          swalAlert({
            title: "THANK YOU FOR YOUR ORDER!",
            text: (response.message),
            icon: 'https://cdn.dribbble.com/users/1782673/screenshots/7094387/media/36e8bc1fa63eff4348fb24193272dda1.gif',
            showConfirmButton: false,
            button: (this.props.history.push("/myorder")),
          });
        } else {
          toast.error(response.message)
        }
      })

  }

  MakePurchase() {
    let { cartList } = this.state;
    let orderList = []
    cartList.map(data => {
      return orderList.push({ productId: data.productId._id, productName: data.productId.name, productImage: data.productId.image, quantity: data.quantity, price: data.price })
    })
    let formData = new FormData();
    if (this.state.couponId) {
      formData.append("couponId", this.state.couponId)
    }
    formData.append("userId", this.state.userId);
    formData.append("payment_method", this.state.payment_method);
    formData.append("orderAmount", this.state.orderAmount ? this.state.orderAmount : this.state.Total_price);
    formData.append("orderList", JSON.stringify(orderList));
    AddTocartService.MakePurchase(formData)
      .then(response => {
        if (response.status === true) {
          localStorage.removeItem("Count")
          swalAlert({
            title: "THANK YOU FOR YOUR ORDER!",
            text: (response.message),
            icon: 'https://cdn.dribbble.com/users/1782673/screenshots/7094387/media/36e8bc1fa63eff4348fb24193272dda1.gif',
            showConfirmButton: false,
            button: (this.props.history.push("/myorder")),
          });
        } else {
          toast.error(response.message)
        }
      })

  }

  render() {
    const {userData, cartList, couponsList } = this.state
    let gstAmount = ((this.state.Total_price)/100)*18
    return (
      <>
      <HeaderComponent />

      <HelmetProvider>
        <Helmet>
          <title>Cart</title>
        </Helmet>
      </HelmetProvider>

      <div className="container">
        <div className='m-2 mb-4'>
          <h2>Cart</h2>
        </div>
        <div className="row mt-2">
          <aside className="col-md-8">
            {
              (cartList) && 
              <>
                {(this.state.isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : ( 
                  <>
                      {cartList.map((data, i) => {
                          return (
                                    <div className='mb-3 shadow p-3 mb-5 bg-body rounded' key={i}>
                                      <div className='container'>
                                        <div className="row d-flex justify-content-center align-items-center">
                                          <div className="col-md-3 text-center">
                                            <img src={process.env.REACT_APP_STORAGE_URL + data.productId.image} alt="product_img" height='150px' width='150px' /> 
                                          </div>

                                          <div className="col-md-5">
                                            <table className="table table-borderless">
                                                <thead></thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                        <td>Product :</td>
                                                        <td>{data.productId.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Price :</td>
                                                        <td>{data.price - (data.price * data.productId.discount)/100}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                          </div>

                                          <div className='col-md-3 '>
                                            <div className='quantity_increment'>
                                              <button type="button" onClick={() => this.decrement(i, data)} className="btn btn-light"> <FaMinus /> </button>
                                                <span > {data.quantity} </span>
                                              <button type="button" onClick={() => this.increment(i, data)} className="btn btn-light"> <FaPlus /> </button>
                                            </div>
                                          </div>

                                          <div className='col-md-1' onClick={() => this.removeCart(data._id)}>
                                            <div className='delete_icon'>
                                              <FaTrash className='icon' type='button'  />    
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    // <div className="card product_Card">
                                    //   <div className="row align-items-center">
                                    //     <div className="col-md-3">
                                    //       <img src={process.env.REACT_APP_STORAGE_URL + data.productId.image} className="img-fluid rounded-start" alt={data.productId.name} /> <br></br>
                                    //     </div>
                                    //     <div className="col-md-9">
                                    //       <div className="card-body">
                                    //         <div className="container">
                                    //           <div className="row">
                                    //             <div className="col-5">
                                    //               <div className="price-wrap fw-bold">Product:  <var className="text-secondary text-capitalize">{data.productId.name}</var></div>
                                    //               <div className="price-wrap fw-bold">Price <var className="price text-secondary "> {data.price}</var></div>
                                    //             </div>
                                    //             <div className='col-5'>
                                    //                 <button type="button" onClick={() => this.decrement(i, data)} className="btn btn-light"> <FaMinus /> </button>
                                    //                 <span > {data.quantity} </span>
                                    //                 <button type="button" onClick={() => this.increment(i, data)} className="btn btn-light"> <FaPlus /> </button>
                                    //             </div>
                                    //             <div className="col-2 text-center">
                                    //               <FaTrash style={{ color: "red" }} type='button' onClick={() => this.removeCart(data._id)} />
                                    //             </div>
                                    //           </div>
                                    //         </div>
                                    //       </div>
                                    //     </div>
                                    //   </div>
                                    // </div>
                                  )
                      })}
                  </>
                )}
              </>
            }
            
          </aside>

          <aside className="col-md-4">
            <div className="card mb-3">
              <div className="card-body">
                <form>
                  <div className="form-group"> <label>Have coupon?</label> <b data-bs-toggle="modal" data-bs-target="#exampleModal">More Coupon</b>
                    <input type="text" className="form-control coupon col-md-8" placeholder="Coupon code" value={this.state.selectCouponData.coupon_code} />
                    <button type='button' className="btn btn-primary btn-apply mt-2 py-2"  onClick={() => this.ApplyCoupon(this.state.selectCouponData)}>Apply</button> 
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div>
                    <h6 className="fs-4 fw-bold">Payment Method</h6>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="a" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Cash On Delivery
                        </label>
                    </div>
                </div>
                <hr />
                <div>
                <table className="table table-borderless">
                    <thead></thead>
                    <tbody className="tableBody">
                        <tr>
                            <td>Price :</td>
                            <td>{this.state.Total_price}</td>
                        </tr>
                        {/* <tr>
                            <td>DiscountAmount :</td>
                            <td></td>
                        </tr> */}
                        <tr>
                            <td>GST (18%) :</td>
                            <td>{gstAmount.toFixed()}</td>
                        </tr>
                        <tr className="total">
                            <td >Total :</td>
                            <td >₹{(this.state.Total_price + gstAmount )}</td>
                        </tr>
                    </tbody>
                </table>
                        {
                          (this.state.cartList.length) ? (<> <hr /><button className="btn btn-out btn-success btn-square btn-main mt-2 " data-bs-toggle="modal" data-bs-target="#profileModalToggle" data-abc="true" >Make Purchase</button></>) : (<> <hr /><button disabled className="btn btn-out btn-success btn-square btn-main mt-2" data-abc="true">Make Purchase</button></>)
                        }
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel"> Coupon code</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {couponsList.map(data => {
              return (
                <ol className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2">
                      <div className="fw-bold">couponName : {data.couponName}</div>
                      <div className="fw-bold">MinAmount : {data.minAmount}</div>
                      <div className="fw-bold">Code :<b className='text-danger'>{data.coupon_code}</b></div> <div className='fw-bold'> Description : {data.description}</div> </div>
                    {data.type === "Rs" ? (<div>{data.type} : {data.descount} </div>) : (<div>{data.type} : {data.descount} </div>)}
                    <span className="badge bg-primary rounded-pill">{data.UsesPerCoupon}</span>
                    <button type="button" className="btn btn-success" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.selectCoupon(data)}>Select</button>
                  </li>
                </ol>
              )
            })}
          </div>
        </div>
      </div>

      {/* profile */}
      <div className="modal fade" id="profileModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Profile</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                  <div>
                      <table className="table table-borderless">
                          <thead></thead>
                          <tbody className="tableBody">
                              <tr>
                                  <td className="tablehead">Name :</td>
                                  <td>{userData.name}</td>
                              </tr>
                              <tr>
                                  <td>Phone :</td>
                                  <td>{userData.phone}</td>
                              </tr>
                              <tr>
                                  <td>Address :</td>
                                  <td>{userData.block + ", " + userData.houseno + ", " + userData.address}</td>
                              </tr>
                              <tr>
                                  <td >Email :</td>
                                  <td >{userData.email}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                    {/* <b> name :</b><span class="badge text-bg-light"> {userData.name}</span> <br />
                    <b> phone :</b><span class="badge text-bg-light"> {userData.phone}</span><br />
                    <b> address :</b><span class="badge text-bg-light"> {userData.block + ", " + userData.houseno + ", " + userData.address}</span><br /> */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-success" onClick={this.ConformOrder} data-bs-dismiss="modal">Confirm Order</button>
                  <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#profileModalToggle2">Update Profile</button>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="profileModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name1" className="form-label">Name</label>
                  <input type="text" defaultValue={userData.name} ref={(input) => this.name = input} name='name' className="form-control" placeholder="Name" />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">Enter your phone No</label>
                  <div id="sign-in-button"></div>
                  <input type="number" name='phone' ref={(input) => this.phone = input} className="form-control" placeholder='Mobile number' defaultValue={userData.phone} required />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">Enter your Block</label>
                  <div id="sign-in-button"></div>
                  <input type="text" name='block' ref={(input) => this.block = input} className="form-control" placeholder='block number' defaultValue={userData.block} required />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">Enter your houseno</label>
                  <div id="sign-in-button"></div>
                  <input type="text" name='houseno' ref={(input) => this.houseno = input} className="form-control" placeholder='houseno number' defaultValue={userData.houseno} required />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">Enter your Address</label>
                  <div id="sign-in-button"></div>
                  <input type="text" name='address' ref={(input) => this.address = input} className="form-control" placeholder='address' defaultValue={userData.address} required />
                </div>
                <button type="submit" data-bs-dismiss="modal" className="btn btn-success">Save and Conform Order</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
    )
  }
}

export default withRouter(Cart)