import React, { Component } from 'react'
import Footer from '../../template/Footer'
import CarAccessoriesService from '../../../services/CarAccessories'
import Auth from '../../../services/CheckToauth'
import AddTocartService from '../../../services/AddTocart'
import service_img from "../../../assets/image/car_service_provider/img_3.png";
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import { FaCartPlus, FaRupeeSign } from "react-icons/fa";
import RoutesTab from "./route"
import ReactLoading from 'react-loading';
import AuthService from "../../../services/auth"
import { Helmet, HelmetProvider } from 'react-helmet-async';

let phone = localStorage.getItem('phone')

class Car_Accessories extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      isshow: false,
      CarAccessoriesData: [],
      Price:'',
      Discount:'',
      UserId:'',
      searchVal:''
    };
  }

  componentDidMount() {
    this.get_CarAccessories();
    this.get_User()
    this.cartList()
  }

  get_User() {
    AuthService.login({phone:phone})
      .then(response =>{
          this.setState({UserId:response.users._id})
      }).catch(err =>{
        console.log(err);
      })
  }

  get_CarAccessories() {
    try {
      CarAccessoriesService.getData().then((response) => {
        response.carAccessories.filter(data=> this.setState({ Price:Number(data.price)}))
        this.setState({ CarAccessoriesData: response.carAccessories});
        setTimeout(() => {
          this.setState({isLoading : false , isshow: true})
        }, 200);

        // afterDiscount = price - (price * discount) / 100;

      });
    } catch (error) {
      console.log(error);
    }
  }

  addTocart(values) {
    let checkAuth = Auth();
    if (checkAuth) {
      this.addcart(values)
    } else {
      this.props.history.push("/login");
    }
  }

  addcart(values) {

    const fmData = new FormData();
    fmData.append("userId", localStorage.getItem('userId'));
    fmData.append("productId", values["_id"]);
    fmData.append("quantity", 1);
    fmData.append("price", values["price"]);
    AddTocartService.Addcarts(fmData)
      .then(response => {
        if (response.status === true) {
          this.props.history.push("/cart")
          toast.success(response.message)
        } else {
          toast.error(response.message)
        }
      })
  }

  cartList() {
    AddTocartService.CartList({ userId: localStorage.getItem('userId') }).then(res=>{
      localStorage.setItem("Count",res.cartList.length)
    }).catch(err =>{
      console.log(err);
    })
  }

  filterNames = ({ name }) => {
    return name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
  };

  render() {
      const { isshow, CarAccessoriesData } = this.state
    return (
      <>
        {/* <Header /> */}
        {/* <Manufacturer_Select /> */}
        <RoutesTab />

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <div className="input-group">
                  <input className="form-control border-end-0 border rounded-pill" onChange={(e) => this.setState({searchVal: e.target.value})} value={this.state.searchVal} type="search" placeholder="search" id="example-search-input" />
              </div>
            </div>
          </div>
        </div>

        <HelmetProvider>
          <Helmet>
            <title>CarAccessories</title>
          </Helmet>
        </HelmetProvider>

        <section className='sec_main'>
          <div className='container'>
            <div className='car_accessories'>
              <div className='sec_title'>
                <h4>Car Accessories</h4>
              </div>

              <div className="sec_subtitle">
                <p></p>
              </div>

              {
                (this.state.isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : (
                <div className="row">
                    {CarAccessoriesData.filter(this.filterNames).map((data, id) => {
                      return (
                        <div className="col-md-6 col-lg-3" key={id}>
                          <div className="list_box_cars_accessories shadow-lg bg-body rounded">
                            {data.image ? (
                              <img src={process.env.REACT_APP_STORAGE_URL + data.image}
                                alt="img" className="w-100" />
                            ) : (
                              <img src={service_img} alt="img" className="w-100" />
                            )}
                            <hr></hr>
                            <div className="list_car_access_text pt-3">
                              <h5 className='fw-bold text-capitalize'>{data.name}</h5>
                              <span className='text-success'>Up to {data.discount}% Off</span>
                              <p><span className='text-decoration-line-through' style={{"fontSize":"12px","color":"#686868	"  }}>Rs. {data.price}</span><span className="primary fw-bold mt-2"><FaRupeeSign />{data.price - (data.price * data.discount)/100}</span></p>
                               {/* afterDiscount = price - (price * discount) / 100; */}

                            </div>
                            <div className='add_to_cart'>
                              <button type="button" onClick={() => this.addTocart(data)}><FaCartPlus /> Add To Cart</button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
                )
              }
            </div>
          </div>
        </section>
        
        {isshow && <Footer />}
      </>
    )
  }
}
export default withRouter(Car_Accessories)