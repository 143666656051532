import React, { Component } from "react";
import Services from "../../../services/Services";

export default class Manufacturer_Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ACCESSORIES_MANUFACTURER_IMAGE_URL: "",
      ACCESSORIES_BRANDMODEL_IMAGE_URL: "",
      ACCESSORIES_FUELTYPE_IMAGE_URL: "",
      ManufacturerShow: false,
      Manufacturer: [],
      ModelData: [],
      FuelTypeData: [],
      SelectedCarData: "",
      ModelID: "",
      FuelTypeID: "",
    };
  }

  componentDidMount() {
    this.Get_Manufacturer()
    let manufacturer_model_fueltypes = sessionStorage.getItem('manufacturer_model_fueltypes')
    if (manufacturer_model_fueltypes) {
        Services.SeletedCarService({ id : manufacturer_model_fueltypes }).then(response => {
            let data = response.manufacturer_model_fueltypes
            this.setState({ ModelID:data.manufacturerId , FuelTypeID:data.manufacturer_modelId,SelectedCarData:data })
            this.Get_Model(data.manufacturerId)
            this.Get_FuelType(data.manufacturer_modelId._id)
        })
    }
  }

  Get_Manufacturer() {
    Services.Manufacturer().then(response => {
            this.setState({ManufacturerShow: true, ACCESSORIES_MANUFACTURER_IMAGE_URL: response.ACCESSORIES_MANUFACTURER_IMAGE_URL, Manufacturer: response.result })
        })
    }

  Get_Model = (value) => {
    Services.Model({ id: value }).then(response => {
            this.setState({ ModelID: value,ACCESSORIES_BRANDMODEL_IMAGE_URL: response.ACCESSORIES_BRANDMODEL_IMAGE_URL, ModelData: response.getModel })
        })
    }

  Get_FuelType = (value) => {
        Services.FuelType({ id: value })
            .then(response => {
                this.setState({ FuelTypeID: value, ACCESSORIES_FUELTYPE_IMAGE_URL: response.ACCESSORIES_FUELTYPE_IMAGE_URL, FuelTypeData: response.data })
            })
    }

    reset() {
        sessionStorage.removeItem('manufacturer_model_fueltypes')
        this.setState({ SelectedCarData:"",ModelID: "", FuelTypeID: "" })
        this.props.reset()
        this.Get_Manufacturer()
    }

  render() {
    const {SelectedCarData,ManufacturerShow,Manufacturer,ModelID,ModelData,FuelTypeID,FuelTypeData} = this.state
    return (
      <>
        <div className="m-5">
          <div className="container text-center">
            <div className="row">
              <div className="col position-reletive">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => this.Get_Model(e.target.value)}
                >
                  <option selected> {SelectedCarData ? SelectedCarData.manufacturerId.brand_title : "Select Manufacturer"}</option>
                    {ManufacturerShow && Manufacturer.map((data) => {
                        return (
                            <option key={data._id} value={data._id}>{data.brand_title}</option>
                        )
                    })}
                </select>
              </div>
              <div className="col">
                {ModelID && 
                    <select className="form-select" aria-label="Default select example" onChange={(e)=>this.Get_FuelType(e.target.value)} >
                        <option selected>{SelectedCarData ? SelectedCarData.manufacturer_modelId.title : "Select Model"}</option>
                        {
                            ModelData.map(data => {
                                return(
                                    <option key={data._id} value={data._id}>{data.title}</option>
                                )
                            })
                        }
                    </select>
                } 
              </div>
              <div className="col">
              {FuelTypeID &&
                <select className="form-select" aria-label="Default select example" onChange={this.props.change}>
                  <option selected>{SelectedCarData ? SelectedCarData.title : "Select FuelType"}</option>
                    {FuelTypeData.map(data => {
                        return (
                            <option key={data._id} value={data._id}>{data.title}</option>
                        )
                    })}
                </select>
                }
                </div>
                {
                    FuelTypeID && 
                    <div className="col-sm-1">
                        <div className="col-4 change_btn" onClick={()=>this.reset()}><span type="button">CHANGE</span></div>
                    </div>
                }
            </div>
          </div>
        </div>
      </>
    );
  }
}
