/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import FooterComponent from "../../template/Footer";
import HeaderComponent from "../../template/Header";
import "./index.css";
import MapComponent from "./Map";
import ReactStars from "react-rating-stars-component";
import Services from "../../../services/Services";
import { FaRupeeSign, FaCartPlus } from "react-icons/fa";
import Manufacturer_Select from "../Manufacturer_Select";
import carOffers from "../../../services/carOffers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { isLogin } from "../../utils";
import { Link, withRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export class Service_Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isshow: false,
      Service_Details: [],
      getOffers: [],
      SelectServiceData: "",
    };
  }

  componentDidMount() {
    this.Service_Details();
    this.getOffers();
    if (this.props.location.state) {
      this.setState({ isshow: true });
    }
    let SelectData = sessionStorage.getItem("manufacturer_model_fueltypes");
    if (SelectData) {
      this.setState({ SelectServiceData: SelectData });
      setTimeout(() => {
        this.setState({ isLoading: false, isshow: true });
      }, 200);
    }
  }

  reset() {
    sessionStorage.removeItem("manufacturer_model_fueltypes");
    this.setState({
      SelectedCarData: "",
      ModelID: "",
      FuelTypeID: "",
      SelectServiceData: "",
    });
  }

  Service_Details() {
    Services.Service_Details({
      carRepairId: this.props.location.state.DetailsData._id,
    }).then((response) => {
      setTimeout(() => {
        this.setState({ Service_Details: response.data });
      }, 100);
    });
  }

  SeletedService(value) {
    Services.SeletedCarService({ id: value }).then((response) => {
      let data = response.manufacturer_model_fueltypes;
      sessionStorage.setItem("manufacturer_model_fueltypes", data._id);
      this.setState({ SelectServiceData: value });
    });
  }

  getOffers() {
    carOffers
      .getOffers()
      .then((response) => {
        this.setState({ getOffers: response.offers });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  scrollUpBtnClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  buyNowBtnClick = (provider, service) => {
    if (!isLogin()) {
      this.props.history.push("/login");
    } else {
      let user = localStorage.getItem("userId");
      let data = {
        providerId: provider,
        userId: user,
        serviceId: service,
      };
      this.props.history.push("/service-cart", data);
    }
  };

  render() {
    const { isshow, Service_Details } = this.state;
    let carType = this.props.location.state.DetailsData.type;

    return (
      <>
        <HeaderComponent />

        {carType === "carBuy" ? null : (
          <Manufacturer_Select
            change={(e) => this.SeletedService(e.target.value)}
            reset={() => this.reset()}
          />
        )}

        <HelmetProvider>
          <Helmet>
            <title>
              {this.props.location.state.DetailsData.name} -
              {this.props.location.state.DetailsData.address}
            </title>
          </Helmet>
        </HelmetProvider>

        <div className="container mt-3">
          <div className="Service_Details">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-end text-end">
                  <li className="breadcrumb-item active" aria-current="page">
                    {this.props.location.state.DetailsData.name}
                  </li>
                  <li className="breadcrumb-item">
                    {this.props.location.state.DetailsData.type ===
                      "carRepair" && (
                      <Link className="text-decoration-none" to="car-service">
                        Car Services
                      </Link>
                    )}
                    {this.props.location.state.DetailsData.type ===
                      "car_wash" && (
                      <Link className="text-decoration-none" to="car-wash">
                        Car Wash
                      </Link>
                    )}
                    {this.props.location.state.DetailsData.type ===
                     "Battery" && (
                      <Link className="text-decoration-none" to="car-battery">
                        Car Battery
                      </Link>
                    )}
                    {this.props.location.state.DetailsData.type ===
                      "carTyre" && (
                      <Link className="text-decoration-none" to="car-tyre">
                        Car Tyre
                      </Link>
                    )}
                  </li>
                  <li className="breadcrumb-item">
                    <Link className="text-decoration-none" to="home">
                      Home
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="shadow p-5 bg-body rounded container">
                  <div className="p-1">
                    {this.props.location.state.DetailsData.photo_reference && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          this.props.location.state.DetailsData.photo_reference
                        }
                        alt="img"
                        width={400}
                        height={200}
                        className="rounded"
                      />
                    )}
                  </div>
                  <div>
                    <h3 className="product-title">
                      {this.props.location.state.DetailsData.name}
                    </h3>
                  </div>
                  <div className="rating">
                    <div className="stars ">
                      <ReactStars
                        count={this.props.location.state.DetailsData.rating}
                        size={30}
                        isHalf={true}
                        value={this.props.location.state.DetailsData.rating}
                        activeColor="#E8DA16"
                      />
                    </div>
                    <span className="review-no">
                      Phone : {this.props.location.state.DetailsData.phone}
                    </span>{" "}
                    <br />
                    <p className="product-description mt-2 text-capitalize">
                      Address : {this.props.location.state.DetailsData.address}{" "}
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  {Service_Details.map((data, id) => {
                    return (
                      <div
                        className="mb-3 shadow p-3 mb-5 bg-body rounded"
                        key={id}
                      >
                        <p className="text-start m-4 fw-bold fs-3">
                          {data.serviceName}
                        </p>
                        <div className="container">
                          <ul className="list-group list-group-flush">
                            <li
                              className="list-group-item"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            ></li>
                          </ul>
                        </div>
                        {this.state.SelectServiceData ? (
                          <div className="row justify-content-around">
                            <div className="col-4 d-flex align-item-baseline fw-bold">
                              <span>
                                <FaRupeeSign size={"15px"} />
                              </span>
                              <span>{data.price}</span>
                            </div>
                            {carType === "carBuy" ? (
                              <div className="col-4">
                                <div className="add_to_cart">
                                  <button type="button">Contact</button>
                                </div>
                              </div>
                            ) : (
                              <div className="col-4">
                                <div className="add_to_cart">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.buyNowBtnClick(
                                        data.carRepairId,
                                        data._id
                                      )
                                    }
                                  >
                                    <FaCartPlus /> Buy Now
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="add_to_cart text-end">
                              <button
                                type="button"
                                onClick={() => this.scrollUpBtnClick()}
                              >
                                Select Car
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="col-md-5">
                <div className="preview-pic tab-content">
                  <div
                    className="shadow p-3  bg-body rounded"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <MapComponent
                      data={this.props.location.state.DetailsData}
                    />
                  </div>
                </div>
                <div className="preview-pic tab-content pt-2">
                  <div
                    className="shadow p-3  bg-body rounded"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <Swiper
                      modules={[
                        Navigation,
                        Pagination,
                        Scrollbar,
                        A11y,
                        Autoplay,
                      ]}
                      spaceBetween={20}
                      slidesPerView={1}
                      loop={true}
                      autoplay={{ delay: 1500 }}
                    >
                      {this.state.getOffers.map((data) => {
                        return (
                          <SwiperSlide>
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_OFFERS_IMAGE +
                                  data.offerImage
                                }
                                alt="offer image"
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isshow && <FooterComponent />}
      </>
    );
  }
}

export default withRouter(Service_Details);
