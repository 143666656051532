import React, { Component } from "react";
import Header from "../../template/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Services from "../../../services/Services";
import { Image } from "antd";
import noimg from "../../../assets/image/No_Image_Available.jpeg";
import { Link } from "react-router-dom";

const containerStyle = { width: "100%", height: "300px" };

export const Map = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBsf7LHMQFIeuA_7-bR7u7EXz5CUaD6I2A",
  });

  const center = {
    lat: Number(props.lat),
    lng: Number(props.lng),
  };

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: center?.lat, lng: center?.lng }}
          zoom={19}
        >
          <Marker
            text="My Marker"
            position={{ lat: center?.lat, lng: center?.lng }}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default class BuyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      cars: [],
      carDetails: [],
      carId: [],
      clickedImg: "",
      currentIndex: "",
      isOpen: false,
    };
  }

  componentDidMount() {
    this.MyCars();
  }

  MyCars = async () => {
    let id = this.props.location.state.details._id;
    let cars = [];
    await Services.Usercars({ id })
      .then((response) => {
        cars.push(response?.cars);
        this.setState({ cars: response.cars });
      })
      .catch((err) => {
        console.log(err);
      });

    cars[0].map(async (data) => {
      let id = data.manufacturerFuelId;
      let carData = this.state.carDetails;
      await Services.carsDetail({ id }).then((res) => {
        return <>{carData.push(res)}</>;
      });
      this.setState({ carDetails: carData });
    });
  };

  openDetails = (data, modal) => {
    this.props.history.push({
      pathname: "/car-details",
      state: { details: data, modaldata: modal },
    });
  };

  render() {
    const { carDetails } = this.state;
    return (
      <>
        <Header />

        <HelmetProvider>
          <Helmet>
            <title>CarBuy/Sale</title>
          </Helmet>
        </HelmetProvider>

        <div className="container mt-3">
          <div className="Service_Details">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-decoration-none justify-content-end text-end">
                  <li className="breadcrumb-item active" aria-current="page">
                    {this.props.location.state.details.name}
                  </li>
                  <li className="breadcrumb-item">
                    <Link className="text-decoration-none" to="car-provider">
                      Car Buy/Sale
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link className="text-decoration-none" to="home">
                      Home
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="shadow p-5 bg-body rounded container">
                  <div className="p-1"></div>
                  <div>
                    <h3 className="product-title">
                      {this.props.location.state.details.name}
                    </h3>
                  </div>
                  <div>
                    <span className="review-no">
                      Phone :{this.props.location.state.details.phone}
                    </span>
                    <p className="product-description mt-2 text-capitalize">
                      Address :{this.props.location.state.details.block}-
                      {this.props.location.state.details.houseno},
                      {this.props.location.state.details.address}
                    </p>
                  </div>
                </div>

                {this.state.cars.map((data) => {
                  return (
                    <>
                      {
                        <div className="service_detail_img pt-3">
                          <div className="row mb-3 shadow p-3 mb-5 bg-body rounded">
                            <div className="col-md-4 text-left">
                              {data.carImages.length > 0 ? (
                                <Image.PreviewGroup
                                  preview={{
                                    onChange: (current, prev) =>
                                      console.log(
                                        `current index: ${current}, prev index: ${prev}`
                                      ),
                                  }}
                                >
                                  <Image
                                    width={200}
                                    src={
                                      process.env.REACT_APP_CARS_IMAGE +
                                      data.carImages[0]
                                    }
                                  />
                                </Image.PreviewGroup>
                              ) : (
                                <Image.PreviewGroup
                                  preview={{
                                    onChange: (current, prev) =>
                                      console.log(
                                        `current index: ${current}, prev index: ${prev}`
                                      ),
                                  }}
                                >
                                  <Image width={200} src={noimg} />
                                </Image.PreviewGroup>
                              )}
                            </div>
                            <div className="col-md-8">
                              <div>
                                {carDetails.map((modal) => {
                                  return (
                                    <>
                                      {data.manufacturerFuelId ===
                                        modal.type._id && (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openDetails(data, modal)
                                          }
                                        >
                                          <h2 className="text-start fw-bold fs-5">
                                            {" "}
                                            {modal.company.brand_title}
                                          </h2>
                                          <h5>
                                            {" "}
                                            {modal.modal.title} ,{" "}
                                            <span>{modal.type.title}</span>
                                          </h5>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                              <div className="mt-2">
                                {data.carSellPrice ? (
                                  <h3 className="text-start fs-5">
                                    Car price:₹ {data.carSellPrice}
                                  </h3>
                                ) : (
                                  <h3 className="text-start fs-5">
                                    Car price:N/A
                                  </h3>
                                )}
                                <h4 className="text-start fs-5">
                                  Car manufacturer Year: {data.manufacturerYear}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </>
                  );
                })}
              </div>
              <div className="col-md-5">
                <div className="preview-pic tab-content">
                  <div
                    className="shadow p-3  bg-body rounded"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <Map
                      lat={this.props.location.state.details.lat}
                      lng={this.props.location.state.details.lng}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
