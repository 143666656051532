import React, { Component } from 'react'
import HeaderComponent from "../../template/Header";
import FooterComponent from "../../template/Footer";
import "./index.css"
import AuthService from "../../../services/auth"
import { toast } from 'react-toastify';
import RoutesProfile from "./route"
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userData: [],
            getAddress:'',
            _id:'',
            name: '',
            phone: '',
            email:'',
            block: '',
            houseno: '',
            setAddress:{
                address:'',
                lat:0,
                lng:0
              }
        }
    }

    componentDidMount() {
        this.getuser()
    }

    formRef = React.createRef();

    getuser(){
        AuthService.login({phone: localStorage.getItem("phone")})
        .then(response =>{
            this.setState({userData:response.users})
        })
    }

    getLatLngByLocation = (location) => {
        geocodeByAddress(location.label)
        .then((result) => getLatLng(result[0]))
        .then(({lat,lng})=>{
          var address = {
            address:location?.label,
            lat:lat,
            lng:lng
          }
          this.setState({ setAddress : address })
        })
    }

    handleChange(event) {
        this.setState({ ...this.state , [event.target.name]:event.target.value })
    }

    // handleAdress(name, value){
    //     let state = this.state;
    //     state[name] = value;
    //     this.setState({state});    
    // }

    // handleSelect = (event) => {
    //     geocodeByAddress(event)
    //     .then(results => getLatLng(results[0]))
    //     .then(({lat, lng})=>{
    //         localStorage.setItem('lat',lat)
    //         localStorage.setItem("lng",lng);
    //     })
    //     .catch(error => console.error('Error', error));
    // };

    handleSubmit(e) {
        e.preventDefault()
        const {_id, name,phone,email,block,houseno,address } = this.state
        const fmData = new FormData();
        fmData.append("id", _id);
        fmData.append("name",name);
        fmData.append("phone",phone);
        fmData.append("email",email);
        fmData.append("block",block);
        fmData.append("houseno",houseno);
        // fmData.append("address",address);
        fmData.append("address", this.state.setAddress?.address);
        fmData.append("lat", this.state.setAddress?.lat);
        fmData.append("lng", this.state.setAddress?.lng);

        AuthService.userUpdate(fmData)
        .then(response =>{
            if(response.status === true){
                this.getuser()
                toast.success(response.message)
            }
        })
    }

    editUser(userId) {
        AuthService.userEdit(userId)
            .then(response => {
                
                this.setState({
                _id: response.data._id,
                name: response.data.name, 
                phone: response.data.phone,
                email: response.data.email,
                block: response.data.block, 
                houseno: response.data.houseno, 
                address: response.data.address,
                getAddress:response.data.address
            })
            })
    }

    render() {
        const {userData, name, phone,email,block, houseno,getAddress } = this.state
        return (
            <>
             
            <HeaderComponent />

            <HelmetProvider>
                <Helmet>
                    <title>Profile</title>
                </Helmet>
            </HelmetProvider>
            
            <div className="container mt-5">
                <div className='sec_title text-center'> <h4>My Profile</h4></div>
                <div className="sec_subtitle"> <p></p></div>
                <div className="row">
                    <div className="col-12 mb-5">
                        <RoutesProfile />
                    </div>
                    <div className="col-12">
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <div className="container" >
                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Full Name</p></div>
                                    <div className="col-md-10"><p className="text-capitalize">{userData.name}</p></div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Phone</p></div>
                                    <div className="col-md-10"><p className="text-capitalize">{userData.phone}</p></div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Email</p></div>
                                    <div className="col-md-10"><p>{userData.email}</p></div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Block No</p></div>
                                    <div className="col-md-10"><p className="text-capitalize">{userData.block}</p></div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Houseno</p></div>
                                    <div className="col-md-10"><p className="text-capitalize">{userData.houseno}</p></div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2"><p className="fw-bold">Address</p></div>
                                    <div className="col-md-10"><p className="text-capitalize">{userData.address}</p></div>
                                </div>
                                <div className="d-grid gap-2 d-md-flex ">
                                    <button type="button" className="btn btn-secondary fw-bold btn-sm" data-bs-target="#exampleModal" data-bs-toggle="modal" onClick={() => this.editUser(userData._id)}>Change Profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* profile model  */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Update Profile</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form >
                                <div className="form-group mb-3">
                                    <label htmlFor="name1" className="form-label">Email Name</label>
                                    <input type="text" value={name} name='name' onChange={this.handleChange.bind(this)} className="form-control" id="name1" placeholder="Name" />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Enter your phone No</label>
                                    <div id="sign-in-button"></div>
                                    <input type="number" name='phone' onChange={this.handleChange.bind(this)} className="form-control" placeholder='Mobile number' value={phone} required />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Enter your email</label>
                                    <div id="sign-in-button"></div>
                                    <input type="email" name='email' onChange={this.handleChange.bind(this)} className="form-control" placeholder='Email' value={email} required />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Enter your Block</label>
                                    <div id="sign-in-button"></div>
                                    <input type="text" name='block' onChange={this.handleChange.bind(this)} className="form-control" placeholder='block number' value={block} required />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Enter your houseno</label>
                                    <div id="sign-in-button"></div>
                                    <input type="text" name='houseno' onChange={this.handleChange.bind(this)} className="form-control" placeholder='houseno number' value={houseno} required />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Enter your Address</label>
                                    <div id="sign-in-button"></div>
                                    {/* <input type="text" name='address' onChange={this.handleChange.bind(this)} className="form-control" placeholder='address' value={address} required /> */}
                                    <GooglePlacesAutocomplete
                                        apiKey="AIzaSyAAaVXRSkjDksyakQJK267YhbY5KZrqvv4"
                                        selectProps={{
                                            isClearable: false,
                                            onChange: (val) => {
                                            this.getLatLngByLocation(val);
                                            },
                                        }}
                                    />
                                    <p>{getAddress}</p>
                                    {/* <input 
                                        {
                                            ...getInputProps({
                                                type:"text", 
                                                name:'address', 
                                                className:"form-control" ,
                                                placeholder:'address',  
                                            })
                                        }
                                    />

                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {
                                            suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';

                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                return(
                                                    <>
                                                        <div {...getSuggestionItemProps(suggestion,{className,style})}>
                                                            <p className='p-1 border-bottom'>{suggestion.description}</p>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }

                                    </div> */}
                                </div>
                                <button type="button" data-bs-dismiss="modal" onClick={this.handleSubmit.bind(this)} className="btn btn-primary">Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>       
        </>
        )
    }
}
