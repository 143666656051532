import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import Header from '../../template/Header'
import ManufacturerSelect from "../../pages/Manufacturer_Select"
import "./index.css"
import Services from '../../../services/Services';

export class route extends Component {

    

    SeletedService(value) {
        Services.SeletedCarService({ id: value })
            .then(response => {
                let data = response.manufacturer_model_fueltypes
                sessionStorage.setItem('manufacturer_model_fueltypes', data._id)
            })
  }

    render() {
        return (
            <>
                <Header />
                <ManufacturerSelect change={(e)=>this.SeletedService(e.target.value)} />
                <nav className="nav container navbar navbar-expand-lg tab_navlink">
                    <NavLink role="button" className="nav-link" to="/car-accesories">Car Accessories</NavLink>
                    <NavLink role="button" className="nav-link" to="/car-battery">Battery</NavLink>
                    <NavLink role="button" className="nav-link" to="/car-tyre">CarTyre</NavLink>
                </nav>
            </>
        )
    }
}

export default route