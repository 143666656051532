import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import "../assets/css/style.css"
import logocar from "../assets/images/logocar.png"
import wheel from "../assets/images/wheel.png"
import "../assets/styles/responsive.css"

export default class Main extends Component {
  render() {
    return (
        <body className="min-vh-100 d-flex flex-column coming_main">
            <div className='coming_page'>
                <header>
                    <div className="container">
                        <nav className="navbar navbar-dark bg-transparenet">
                            <span className="navbar-text ml-auto d-sm-inline-block">+91 99090 16746</span>
                            <span className="navbar-text d-none d-sm-inline-block"></span>
                        </nav>
                    </div>
                </header>
                <main className="my-auto">
                    <div className="container text-center">
                        <Link className="navbar-brand" to='#'>
                                <img src={logocar} alt="logo" className="car"  />
                                <img src={wheel} className="wheel" alt='wheel' />
                        </Link>
                        <h1 className="page-title ">We're coming soon....</h1>
                    </div>
                </main>
            </div>
            
    </body>
    )
  }
}
