/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import Footer from "../../template/Footer";
import Header from "../../template/Header";
import img3 from "../../../assets/image/car_service_provider/img_3.png";
import WashServices from "../../../services/Services";
import { withRouter } from "react-router";
import Manufacturer_Select from "../../pages/Manufacturer_Select";
import ReactLoading from "react-loading";
import Services from "../../../services/Services";
import { Helmet, HelmetProvider } from "react-helmet-async";

class CarsService extends Component {
  constructor(props) {
    super(props);
    this.OpenDetails = this.OpenDetails.bind(this);
    this.state = {
      isLoading: true,
      isshow: false,
      carService: [],
      searchVal:''
    };
  }

  componentDidMount() {
    this.CarServicesProvider();
  }

  CarServicesProvider() {
    try {
      WashServices.servicesProvider().then((response) => {
        this.setState({ carService: response.data });
        this.setState({ isLoading: false, isshow: true });
      });
    } catch (error) {
      console.log(error);
    }
  }

  OpenDetails(DetailsData) {
    this.props.history.push({
      pathname: "/service-details",
      state: { DetailsData: DetailsData },
    });
  }

  SeletedService(value) {
    Services.SeletedCarService({ id: value }).then((response) => {
      let data = response.manufacturer_model_fueltypes;
      sessionStorage.setItem("manufacturer_model_fueltypes", data._id);
    });
  }

  filterNames = ({ name }) => {
    return name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
  };

  render() {
    const { isshow, carService } = this.state;

    return (
      <>
        <Header />
        <Manufacturer_Select
          change={(e) => this.SeletedService(e.target.value)}
        />

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <div className="input-group">
                  <input className="form-control border-end-0 border rounded-pill" onChange={(e) => this.setState({searchVal: e.target.value})} value={this.state.searchVal} type="search" placeholder="search" id="example-search-input" />
              </div>
            </div>
          </div>
        </div>

        <HelmetProvider>
          <Helmet>
            <title>CarService</title>
          </Helmet>
        </HelmetProvider>

        <section className="sec_main" id="carservice">
          <div className="container">
            <div>
              <div className="sec_title">
                <h4>Car Services Provider</h4>
              </div>

              <div className="sec_subtitle">
                <p>
                  Find the best service packages for your car. Select from a
                  wide range of car services, from general service, and
                  accidental repairs to customs repairs, etc...
                </p>
              </div>
              {this.state.isLoading ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#053961"}
                  delay={"1"}
                  height={"20%"}
                  width={"6%"}
                  className="loader"
                />
              ) : (
                <div className="row">
                  {carService.filter(this.filterNames).map((data) => {
                    return (
                      <div className="col-md-6 col-lg-3" key={data._id}>
                        <div
                          className="list_box_cars_wash shadow-lg bg-body rounded"
                          onClick={() => this.OpenDetails(data)}
                        >
                          {data.photo_reference ? (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                data.photo_reference
                              }
                              alt="img"
                              className="w-100"
                            />
                          ) : (
                            <img src={img3} alt="img" className="w-100" />
                          )}
                          <figure className="text-center mt-5">
                            <div className="list_box_text">
                              <h2>{data.name}</h2>
                              <h5 className="pt-4">{data.address}</h5>
                            </div>
                          </figure>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div></div>
            </div>
          </div>
        </section>
        {isshow && <Footer />}
      </>
    );
  }
}
export default withRouter(CarsService);
