import Call from "./Call";

const getData = async () => {
    let d = await Call({
      path: "api/admin/carAccessories",
      method: "GET",
    });
    return d;
  };

  const Get_CarTyre = async () => {
    let d = await Call({
      path: "api/admin/cartyre-services",
      method: "GET",
    });
    return d;
  };

  const Get_Battery = async () => {
    let d = await Call({
      path: "api/admin/car-battery",
      method: "GET",
    });
    return d;
  };

  const exportObject = {getData,Get_CarTyre,Get_Battery}
  export default exportObject