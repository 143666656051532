import React from "react";
import Logo from "../../assets/image/footer_img.png";
import apple from "../../assets/image/apple.png";
import android from "../../assets/image/playstore.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook,faInstagram,faLinkedin,faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="row text-center align-items-center">
        <div className="col-md-6 col-lg-4 ">
          <div className="footer_logo">
            <img src={Logo} height="50" alt="logo" />
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="download_icon">
            <Link to="#"><img src={apple} height="30" alt="phone" /></Link>
            <Link to="#"><img src={android} height="30" alt="phone" /></Link>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="social_icons">
            <ul>
              <li><Link to="#"><FontAwesomeIcon icon={faFacebook} /></Link></li>
              <li><Link to="#"><FontAwesomeIcon icon={faInstagram} /></Link></li>
              <li><Link to="#"><FontAwesomeIcon icon={faTwitter} /></Link></li>
              <li><Link to="#"><FontAwesomeIcon icon={faLinkedin} /></Link></li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
