

// export const logout = () => {
//   localStorage.removeItem(TOKEN_KEY);
// };

export const isLogin = () => {
  if (localStorage.getItem("accessToken")) {
    return true;
  }
  return false;
};
