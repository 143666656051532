/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../template/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import AuthService from "../../../services/auth";
import CarMap from "./map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faGasPump,
  faGauge,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";

const CarDetails = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    user();
  }, []);

  const user = async () => {
    let userId = props.location.state.details.userId;
    AuthService.userEdit(userId).then((response) => {
      setUserData(response.data);
    });
  };

  return (
    <>
      <Header />

      <HelmetProvider>
        <Helmet>
          <title>Car details</title>
        </Helmet>
      </HelmetProvider>

      <div className="container mt-3">
        <div className="Service_Details">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb text-decoration-none justify-content-end text-end">
                <li className="breadcrumb-item active" aria-current="page">
                  {userData.name}
                </li>
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="car-provider">
                    Car Buy/Sale
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="home">
                    Home
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="shadow py-2 bg-body rounded container mb-3">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {props.location.state.details.carImages.map((img, index) => {
                    return (
                      <SwiperSlide>
                        <div className="carimg">
                          <img src={process.env.REACT_APP_CARS_IMAGE + img} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {props.location.state.details.carImages.map((img, index) => {
                    return (
                      <SwiperSlide>
                        <div className="carimg2">
                          <img src={process.env.REACT_APP_CARS_IMAGE + img} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>

              <div className="shadow py-2 bg-body rounded container mb-3">
                <h3>
                  {props.location.state.modaldata.company.brand_title} (
                  {props.location.state.details.manufacturerYear})
                </h3>
                <div className="row">
                  <div className="col">
                    <div className="overview_box">
                      <div className="overview_icon">
                        <FontAwesomeIcon icon={faGasPump} />
                      </div>
                      <div className="overview_info">
                        <p>{props.location.state.modaldata.type.title}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {props.location.state.details.carKilometer && (
                      <div className="overview_box">
                        <div className="overview_icon">
                          <FontAwesomeIcon icon={faGauge} />
                        </div>
                        <div className="overview_info">
                          <p>{props.location.state.details.carKilometer} KM</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="shadow py-2 bg-body rounded container mb-3">
                <h2 className="fw-normal">Overview</h2>
                <hr />
                <div className="row">
                  <div className="col">
                    <div className="overview_box">
                      <div className="overview_icon">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>
                      <div className="overview_info">
                        <h4>Location</h4>
                        <p>{userData.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="overview_box">
                      <div className="overview_icon">
                        <FontAwesomeIcon icon={faCalendarDay} />
                      </div>
                      <div className="overview_info">
                        <h4>Posting date</h4>
                        <p>
                          {moment(
                            props.location.state.details.createdAt
                          ).format("MM/DD/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {props.location.state.details.description && (
                <div className="shadow py-2 bg-body rounded container mb-3">
                  <h2 className="fw-normal">Description</h2>
                  <hr />
                  <div className="overview_box">
                    <p>{props.location.state.details.description}</p>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-5">
              <div className="preview-pic tab-content">
                <div
                  className="shadow p-3  bg-body rounded"
                  style={{ height: "100%", width: "100%" }}
                >
                  <h2 className="fs-3 fw-bolder">
                    ₹{props.location.state.details.carSellPrice}
                  </h2>
                </div>
              </div>
              <div className="preview-pic tab-content  pt-3">
                <div
                  className="shadow p-3  bg-body rounded"
                  style={{ height: "100%", width: "100%" }}
                >
                  <h3>{userData.name}</h3>

                  <div className="p-3">
                    <CarMap lat={userData.lat} lng={userData.lng} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarDetails;
