import Call from "./Call";

const login = async (data) => {
    let d = await Call({
      path: "api/users/userlogin",
      method: "post",
      data,
    });
    return d;
  };

  const userEdit = async (id) => {
    let d = await Call({
      path: "api/admin/userEdit",
      method: "POST",
      data: {id:id}
    });
    return d;
  };

  const userUpdate = async (data) => {
    let d = await Call({
      path: "api/admin/userUpdate",
      method: "POST",
      data,
    });
    return d;
  };

  const exportObject = {login,userEdit,userUpdate}

  export default exportObject