import React, { Component } from "react";
import Services from "../../../services/Services";
import HeaderComponent from "../../template/Header";
import AuthService from "../../../services/auth"
import './index.css'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { toast } from "react-toastify";
import swalAlert from 'sweetalert';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default class Buynow_cart extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            service_data : [],
            userData: [],
            _id:'',
            name:'',
            phone:'',
            email:'',
            block:'',
            houseno:'',
            address:'',
            errors: {}
        }
    }
    
    componentDidMount() {
        this.service_detail()
        this.getuser()
    }

    getuser(){
        AuthService.login({phone: localStorage.getItem("phone")})
        .then(response =>{
            this.setState({userData:response.users})
        })
    }
    
    getLatLngByLocation = (location) => {
        geocodeByAddress(location.label)
        .then((result) => getLatLng(result[0]))
        .then(({lat,lng})=>{
          var address = {
            address:location?.label,
            lat:lat,
            lng:lng
          }
          this.setState({ setAddress : address })
        })
    }

    handleChange(event) {
        this.setState({ ...this.state, [event.target.name]:event.target.value })
    }

    handleAdress(name, value){
        let state = this.state;
        state[name] = value;
        this.setState({state});    
    }

    handleSelect = (event) => {
        geocodeByAddress(event)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng})=>{
            localStorage.setItem('lat',lat)
            localStorage.setItem("lng",lng);
        })
        .catch(error => console.error('Error', error));
    };

    service_detail() {
        let providerId = this.props.history.location.state.providerId
        Services.Service_Details({carRepairId:providerId}).then(res => {
            this.setState({service_data : res.data })
        }).catch(err => {
            console.log(err);
        })
    }

    editUser(userId) {
        AuthService.userEdit(userId)
            .then(response => {
                this.setState({_id:response.data._id, name: response.data.name, phone: response.data.phone,email: response.data.email,block: response.data.block, houseno: response.data.houseno, address: response.data.address })
            })
    }

    validateForm(){

        let fields = this.state
        let errors = {}
        let formValid = true

        if (!fields["name"]) {
            formValid = false;
            errors["name"] = "*Please enter your username.";
        }

        this.setState({
            errors: errors
          });
          return formValid;
    }

    handleSubmit(e){

        e.preventDefault()
    
        const {_id, name,phone,email,block,houseno,address } = this.state    
        
        const fmData = new FormData();
        fmData.append("id", _id);
        fmData.append("name",name);
        fmData.append("phone",phone);
        fmData.append("email",email);
        fmData.append("block",block);
        fmData.append("houseno",houseno);
        fmData.append("address",address);
        AuthService.userUpdate(fmData)
          .then(response => {
            if (response.status === true) {
                this.getuser()
                toast.success(response.message)
            }
          })
    }

    buyNowBtnClick = (provider, service) => {
        
        let user = localStorage.getItem('userId');
        let data = {
            "providerId": provider, 
            "userId": user,
            "serviceId": service
        }          
        Services.Buynow(data)
            .then(response => {
                if(response.status) {
                    swalAlert({
                        title: "THANK YOU FOR YOUR ORDER!",
                        text: (response.message),
                        icon: "success",
                        showConfirmButton: false,
                        button: (this.props.history.push("/mybookings")),
                      });
                    // alert(response.message);
                } else {
                     toast.error("oops,something went wrong.")
                    // alert('oop, Something went wrong.')
                }
            }).catch((error) => {
                console.log('Error : ', error);
            })
    }

  render() {
      let serviceId = this.props.history.location.state.serviceId
      let providerId = this.props.history.location.state.providerId
      const { userData,name, phone,email,block, houseno,address } = this.state
      return (
      <>
        <PlacesAutocomplete value={address} onChange={this.handleAdress.bind(this, 'address')} onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading  }) => (<> 
            <HeaderComponent />

            <HelmetProvider>
                <Helmet>
                    <title>ServiceCart</title>
                </Helmet>
            </HelmetProvider>
            
            <div className="container">
            <div className="row mt-2">
                
                {/* Service preview */}
                <aside className="col-md-8">
                    {this.state.service_data.map(data=>{
                            return (
                                <div>
                                    {(serviceId === data._id) && (<>
                                            <div className='mb-3 shadow p-3 mb-5 bg-body rounded'>
                                                <p className="offset-md-4 fw-bold text-center fs-3">{data.serviceName}</p>
                                                <div className='container'>
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-md-4 text-center">
                                                            <img src={process.env.REACT_APP_SERVER_URI + data.serviceImages[0]} alt="service_img" height='150px' width='150px' />
                                                        </div>
                                                        <div className="col-md-8">
                                                        <ul className="list-group list-group-flush">
                                                                <li className="list-group-item" dangerouslySetInnerHTML={{ __html: data.description }}></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                        </>)}
                                </div>
                            )})}
                </aside>

                {/* payment confirm */}
                <aside className="col-md-4">
                <div className="card mb-3">
                    <div className="card-body">
                        <form>
                        <div className="form-group"> <label>Have coupon?</label> <b data-bs-toggle="modal" data-bs-target="#exampleModal">More Coupon</b>
                            <input type="text" className="form-control coupon col-md-8" placeholder="Coupon code"  />
                            <button type='button' className="btn btn-primary mt-2 py-2">Apply</button>
                        </div>
                        </form>
                    </div>
                </div>

                <div className="card">
                    {this.state.service_data.map(data=>{
                        let gstAmount = ((data.price)/100)*18
                        return (
                            <>
                            {(serviceId === data._id) && <div className="card-body">
                                    <div>
                                        <h6 className="fs-4 fw-bold">Payment Method</h6>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="a" defaultChecked />
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                Cash On Delivery
                                            </label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <table className="table table-borderless">
                                            <thead></thead>
                                            <tbody className="tableBody">
                                                <tr>
                                                    <td>Price :</td>
                                                    <td>{data.price}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td>DiscountAmount :</td>
                                                    <td>{data.discountPrice}</td>
                                                </tr> */}
                                                <tr>
                                                    <td>GST (18%) :</td>
                                                    <td>{gstAmount}</td>
                                                </tr>
                                                <tr className="total">
                                                    <td >Total :</td>
                                                    <td >₹{(data.price + gstAmount )}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr />
                                    <div>
                                        <button className="btn btn-out btn-success btn-square btn-main mt-2 " data-bs-toggle="modal" data-bs-target="#profileModalToggle" data-abc="true">Make Purchase</button>
                                    </div>
                                </div>}
                            </>
                        )})}
                </div>
                </aside>
            </div>
            </div>

            {/* profile */}
            <div className="modal fade" id="profileModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Profile</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <table className="table table-borderless">
                                            <thead></thead>
                                            <tbody className="tableBody">
                                                <tr>
                                                    <td className="tablehead">Name :</td>
                                                    <td>{userData.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone :</td>
                                                    <td>{userData.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address :</td>
                                                    <td>{userData.block + ", " + userData.houseno + ", " + userData.address}</td>
                                                </tr>
                                                <tr>
                                                    <td >Email :</td>
                                                    <td >{userData.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={() => this.buyNowBtnClick(providerId, serviceId)} data-bs-dismiss="modal">Confirm Order</button>
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#profileModalToggle2" onClick={() => this.editUser(userData._id)}>Update Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="profileModalToggle2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Update Profile</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e)=>this.handleSubmit(e)}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name1" className="form-label">Email Name</label>
                                        <input type="text" value={name} name='name' onChange={this.handleChange.bind(this)} className="form-control text-uppercase" id="name1" placeholder="Name" />
                                        <div className="text-danger">{this.state.errors.name}</div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Enter your phone No</label>
                                        <div id="sign-in-button"></div>
                                        <input type="number" name='phone' onChange={this.handleChange.bind(this)} className="form-control" placeholder='Mobile number' value={phone} required />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Enter your email</label>
                                        <div id="sign-in-button"></div>
                                        <input type="email" name='email' onChange={this.handleChange.bind(this)} className="form-control" placeholder='Email' value={email} required />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Enter your Block</label>
                                        <div id="sign-in-button"></div>
                                        <input type="text" name='block' onChange={this.handleChange.bind(this)} className="form-control" placeholder='block number' value={block} required />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Enter your houseno</label>
                                        <div id="sign-in-button"></div>
                                        <input type="text" name='houseno' onChange={this.handleChange.bind(this)} className="form-control" placeholder='houseno number' value={houseno} required />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Enter your Address</label>
                                        <div id="sign-in-button"></div>
                                        <input 
                                            {
                                                ...getInputProps({
                                                    type:"text", 
                                                    name:'address', 
                                                    className:"form-control" ,
                                                    placeholder:'address',  
                                                })
                                            }
                                        />

                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {
                                                suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';

                                                    const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                    return(
                                                        <>
                                                            <div {...getSuggestionItemProps(suggestion,{className,style})}>
                                                                <p className='p-1 border-bottom'>{suggestion.description}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    <button type="submit" data-bs-dismiss="modal" className="btn btn-primary">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
            </>)}    
        </PlacesAutocomplete>
      </>
    );
  }
}
