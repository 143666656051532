import React, { Component } from 'react'
import img1 from "../../assets/image/visited_categories/img_1.png";
import img2 from "../../assets/image/visited_categories/img_2.png";
import img3 from "../../assets/image/visited_categories/img_3.png";
import img4 from "../../assets/image/visited_categories/img_4.png";
import service_img from "../../assets/image/car_service_provider/img_3.png";
import wheel from "../../assets/image/wheel.png";
import carWashServices from "../../services/Services";
import DownloadApp from "../pages/DownloadApp";
import car from "../../assets/image/home/car_banner.png";
import Header from "../template/Header";
import Footer from "../template/Footer";
import { NavLink, withRouter } from "react-router-dom";
import CarAccessories from '../../services/CarAccessories';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'
import carOffers from '../../services/carOffers';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Home extends Component {

    constructor(props) {
        super(props);

        this.OpenDetails = this.OpenDetails.bind(this)

        this.state = {
            isLoading: true,
            isshow: false,
            carWash: [],
            carService: [],
            getOffers: [],
            serviceProviders: '',
            washerProviders: '',
            accessoriesProviders: ''
        };
    }

    componentDidMount() {

        this.carWashServices();
        this.CarServicesProvider()
        this.carAccessories()
        this.getOffers()
    }

    carWashServices() {
        try {
            carWashServices.washProvider().then((response) => {
                this.setState({ carWash: response.data, isshow: true, washerProviders: response.data.length });
            });
        } catch (error) {
            console.log(error);
        }
    }

    getOffers() {
        carOffers.getOffers().then(response => {
            this.setState({ getOffers: response.offers })
        }).catch(err => {
            console.log(err);
        })
    }

    CarServicesProvider() {
        try {
            carWashServices.servicesProvider().then((response) => {
                this.setState({ carService: response.data, isshow: true, serviceProviders: response.data.length });
            });
        } catch (error) {
            console.log(error);
        }
    }

    carAccessories() {
        try {
            CarAccessories.getData().then(response => {
                this.setState({ accessoriesProviders: response.carAccessories.length })
            })
        } catch (error) {
            console.log(error);
        }
    }

    OpenDetails(DetailsData) {
        this.props.history.push({
            pathname: "/service-details",
            state: { DetailsData: DetailsData }
        });
    }

    render() {
        const { carWash, carService, serviceProviders, washerProviders, accessoriesProviders } = this.state

        const carCategories = [
            { img: img1, title: "Car Service", providers: serviceProviders },
            { img: img2, title: "Car Buy/Sale", providers: '0' },
            { img: img3, title: "Car Wash", providers: washerProviders },
            { img: img4, title: "Car Accessories", providers: accessoriesProviders },
        ]

        return ( 
        <>
            <Header />

            <div className = "home-main">
                <img src = { car } alt = "car" className = "car_img" />

                <div className = "circle_1">
                    <div className = "circle_inner">
                        <img src = { wheel } className = "w-100"  alt="wheel" />
                        <NavLink className = "menu_link" to = { "/car-accesories" }>ACCESSORIES </NavLink> 
                    </div>
                </div>

                <div className = "circle_2">
                    <div className = "circle_inner">
                        <img src = { wheel } className = "w-100" alt="wheel" />
                        <NavLink className = "menu_link"to = { "/car-provider" }>CAR SALE </NavLink> 
                    </div> 
                </div>

                <div className = "circle_3">
                    <div className = "circle_inner">
                        <img src = { wheel } className = "w-100" alt="wheel" />
                        <NavLink className = "menu_link"to = { "/car-provider" }>CAR BUY </NavLink> 
                    </div> 
                </div>

                <div className = "circle_4">
                    <div className = "circle_inner">
                        <img src = { wheel } className = "w-100" alt="wheel" />
                        <NavLink className = "menu_link" to = { "/car-wash" }>CAR WASH </NavLink> 
                    </div> 
                </div>

                <div className = "circle_5">
                    <div className = "circle_inner">
                        <img src = { wheel } className = "w-100" alt="wheel" />
                        <NavLink className = "menu_link" to = { "/car-service" }>CAR SERVICE </NavLink> 
                    </div> 
                </div> 
            </div>

            <HelmetProvider>
                <Helmet>
                    <title> UrbanKars </title> 
                </Helmet>
            </HelmetProvider>

            { /* VisitedCategories */ } 
            <section className = "sec_main" id = "caraccessories">
                <div className = "container">
                    <div className = "visit_categories">
                        <div className = "sec_title">
                            <h4> Most Visited Categories </h4> 
                        </div>

                        <div className = "sec_subtitle">
                            <p> Professional Car service, Car repair, Get pick up and drop, Accessories, Car wash, Car buy or sale, in one - stop solution in your city of lights. </p> 
                        </div>

                        <div className = "row"> {
                            carCategories.map((data, id) => {
                                return ( 
                                    <div className = "col-md-6 col-lg-3" key = {id}>
                                        <div className = "list_box">
                                            <img src = { data.img } alt = "location" />
                                                <div className = "list_text pt-3">
                                                    <h5> { data.title } </h5> 
                                                    <p> { data.providers } </p> 
                                                </div> 
                                        </div> 
                                    </div>
                                );
                            })
                        } 
                        </div> 
                    </div> 
                </div> 
            </section>

            { /* offers */ } 
            <section className = "sec_main" id = "caraccessories">
                <div className = "container">
                    <div className = "visit_categories">
                        <div className = "sec_title pb-5">
                            <h4> Offers </h4> 
                        </div>  

                        <Swiper modules = {[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                spaceBetween = { 20 }
                                slidesPerView = { 4 }
                                loop = { true }
                                autoplay = {{ delay: 2000 }}
                                breakpoints = {
                                    {
                                        360: {
                                            slidesPerView: 1
                                        },
                                        480: {
                                            slidesPerView: 1
                                        },
                                        640: {
                                            slidesPerView: 1
                                        },
                                        768: {
                                            slidesPerView: 2
                                        },
                                        991: {
                                            slidesPerView: 2
                                        },
                                        1199: {
                                            slidesPerView: 4
                                        }
                                    }
                                }
                        >
                            {
                                this.state.getOffers.map((data, id) => {
                                    return ( 
                                        <SwiperSlide >
                                            <div key={id}>
                                                <img  src = { process.env.REACT_APP_OFFERS_IMAGE + data.offerImage } alt = 'offer image' />
                                            </div> 
                                        </SwiperSlide>
                                    )
                                })
                            } 
                        </Swiper> 
                    </div> 
                </div> 
            </section>

            { /* car_service */ } 
            <section className = "sec_main" id = "carservice">
                <div className = "container">
                    <div>
                        <div className = "sec_title">
                            <h4> Car Services Provider </h4> 
                        </div>

                        <div className = "sec_subtitle">
                            <p> Find the best service packages for your car.Select from a wide range of car services, from general service, and accidental repairs to customs repairs, etc... </p> 
                        </div>

                        <div className = "row"> {
                            carService.slice(0, 8).map((data, id) => {
                                return ( 
                                <div className = "col-md-6 col-lg-3" key = { id }>
                                    <div className = "list_box_cars shadow-lg bg-body rounded" onClick = {() => this.OpenDetails(data)}> 
                                        {data.photo_reference ? ( 
                                                <img src = { process.env.REACT_APP_IMAGE_URL + data.photo_reference} alt = "img" className = "w-100" />
                                            ) : ( 
                                                <img src = { service_img } alt = "img" className = "w-100" />
                                            )
                                        } 
                                        <div className = "list_box_text pt-4">
                                            <h2> { data.name } </h2> 
                                            <p> { data.area } </p> 
                                        </div> 
                                    </div> 
                                </div>
                                );
                            })
                        } 
                        </div>

                        <div>
                            <NavLink className = "btn btn-primary" to = "/car-service" role = "button">
                                Explore more 
                            </NavLink> 
                        </div> 
                    </div> 
                </div> 
            </section>

            { /* car wash provider */ } 
            <section className = "sec_main" id = "carwash">
                <div className = "container">
                    <div>
                        <div className = "sec_title">
                            <h4> Car Wash Provider </h4> 
                        </div>
                        <div className = "sec_subtitle">
                            <p>
                                A car wash, carwash, or auto wash is a facility used to clean the exterior, and in some cases the interior of motor vehicles.Car washes can be self - service, full - service, or fully automated. 
                            </p> 
                        </div>
                        <div className = "row"> 
                                {carWash.slice(0, 4).map((data, id) => {
                                    return ( 
                                            <div className = "col-md-6 col-lg-3" key = { id } >
                                                <div className = "list_box_cars shadow-lg bg-body rounded" onClick = {() => this.OpenDetails(data)}> 
                                                    {
                                                        data.photo_reference ? ( 
                                                            <img src = {process.env.REACT_APP_IMAGE_URL + data.photo_reference} alt = "img"className = "w-100" />
                                                        ) : (<img src = { service_img } alt = "img" className = "w-100 " />)
                                                    } 
                                                    <div className = "list_box_text pt-4">
                                                        <h2> { data.name } </h2> <p className = "city_area" > { data.area } </p> 
                                                    </div> 
                                                </div> 
                                            </div>
                                            )
                                })} 
                        </div>
                        <div>
                            <NavLink className = "btn btn-primary" to = "/car-wash" role = "button">Explore more</NavLink> 
                        </div> 
                    </div> 
                </div> 
            </section>

            <DownloadApp />
            <Footer />
        </>
        )
    }
}
export default withRouter(Home)