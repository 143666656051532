import React, { Component } from 'react'
import HeaderComponent from "../../template/Header";
import AddTocartService from '../../../services/AddTocart'
import RoutesProfile from "../ProfilePage/route"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactLoading from 'react-loading';
import Footer from '../../template/Footer';

export default class index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orderList: [],
            couponData: [],
            myorderData: [],
            isLoading:true,
            isshow: false,
            total: ''
        }
    }

    componentDidMount() {
        this.orderList()  
    }

    orderList() {
        AddTocartService.Myorder({ userId: localStorage.getItem('userId') })
            .then(response => {
                this.setState({ orderList: response.Orders })
                if (response.Orders.length > 0) {
                    setTimeout(() => {
                        this.setState({isLoading : false , isshow: true,})
                      }, 200);
                }
            })
    }

    ViewOrder(data) {
        this.setState({ show: true })
        AddTocartService.getOdersUsers(data)
            .then(response => {
                this.setState({ myorderData: response.Orders.Products, couponData: response.Orders.couponId, total: response.Orders.orderAmount })
            
            })
    }

    render() {
        const { orderList, myorderData, couponData,isshow } = this.state

        return (
            <>
                <HeaderComponent />
                
                <HelmetProvider>
                    <Helmet>
                        <title>Orders</title>
                    </Helmet>
                </HelmetProvider>

                <div className="container mt-5">
                    <div className='sec_title text-center'> <h4>My Orders</h4></div>
                    <div className="sec_subtitle"><p></p></div>
                    <div className="row">
                        <div className="col-12 mb-5">
                            <RoutesProfile />
                        </div>
                        <div className="col-12">
                            <div className="shadow p-3 mb-5 bg-body rounded">
                                {
                                    (orderList) && <>
                                        {
                                            (this.state.isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : (
                                                <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col">Order Id</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {orderList.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{data.orderId}</td>
                                                                <td><button type="button" data-bs-target="#exampleModal" data-bs-toggle="modal" className="btn btn-light" onClick={() => this.ViewOrder(data.orderId)}>My Order</button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            )
                                        }
                                    </>
                                }
                            </div>
                            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" >
                                <div className="modal-dialog mt-5">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="exampleModalLabel">My order</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">productImage</th>
                                                        <th scope="col">productName</th>
                                                        <th scope="col">quantity</th>
                                                        <th scope="col">price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {myorderData.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td> <img src={process.env.REACT_APP_STORAGE_URL + data.productImage} alt={data.productName} width={100} /></td>
                                                                <td>{data.productName}</td>
                                                                <td>{data.quantity}</td>
                                                                <td>{data.price}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                            <div className="container text-center">
                                                <div className="row">
                                                    <div className="col-8">
                                                        {couponData &&
                                                            <div>
                                                                <p className="text-end">Coupon Name : {couponData.couponName}</p>
                                                                <p className="text-end">Code : <b className='text-warning bg-dark'>{couponData.coupon_code}</b></p>
                                                                <p className="text-end">Descount : {couponData.descount + "  " + couponData.type}  </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="text-end fw-bold">Total : {this.state.total}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isshow && <Footer />}
            </>
        )
    }
}
