import React, { Component } from 'react'
import WashServices from "../../../services/Services";
import Footer from "../../template/Footer";
import Header from "../../template/Header";
import service_img from "../../../assets/image/car_service_provider/img_3.png";
import { withRouter } from "react-router";
import Manufacturer_Select from "../../pages/Manufacturer_Select"
import ReactLoading from 'react-loading';
import Services from '../../../services/Services';
import { Helmet, HelmetProvider } from 'react-helmet-async';

 class CarWashProvider extends Component {

  constructor(props) {
    super(props);
    this.OpenDetails = this.OpenDetails.bind(this)

    this.state = {
      isLoading:true,
      isshow: false,
      carWashData: [],
      carService: [],
      searchVal:''

    };
  }

  componentDidMount() {
    this.carWashData();
  }

  carWashData() {
    try {
      WashServices.washProvider().then((response) => {
        this.setState({ carWashData: response.data })
        setTimeout(() => {
          this.setState({isLoading : false , isshow: true})
        }, 200);
      });
    } catch (error) {
      console.log(error);
    }
  }

  OpenDetails(DetailsData){
    this.props.history.push( 
       {pathname: "/service-details",
       state: { DetailsData:DetailsData }});
  }

  SeletedService(value) {
    Services.SeletedCarService({ id: value })
      .then(response => {
          let data = response.manufacturer_model_fueltypes
          sessionStorage.setItem('manufacturer_model_fueltypes', data._id)
      })
  }

  filterNames = ({ name }) => {
    return name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
  };

  render() {
    const { isshow, carWashData } = this.state

    return (
      <>
        <Header />
        <Manufacturer_Select change={(e)=>this.SeletedService(e.target.value)} />

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <div className="input-group">
                  <input className="form-control border-end-0 border rounded-pill" onChange={(e) => this.setState({searchVal: e.target.value})} value={this.state.searchVal} type="search" placeholder="search" id="example-search-input" />
              </div>
            </div>
          </div>
        </div>

        <HelmetProvider>
          <Helmet>
              <title>CarWash</title>
          </Helmet>
        </HelmetProvider>

        <section className="sec_main" id="carwash">
          <div className="container">
            <div>
              <div className="sec_title">
                <h4>Car Wash Provider</h4>
              </div>

              <div className="sec_subtitle">
                <p>
                  A car wash, carwash, or auto wash is a facility used to clean
                  the exterior, and in some cases the interior of motor vehicles.
                  Car washes can be self-service, full-service, or fully
                  automated.
                </p>
              </div>

              {
                (this.state.isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : (
                  <div>
                      {
                        (<div className="row">
                          {carWashData.filter(this.filterNames).map((data, id) => {
                            return (
                              <div className="col-md-6 col-lg-3" key={id}>
                                <div className="list_box_cars_wash shadow-lg bg-body rounded" onClick={()=>this.OpenDetails(data)}>
                                  {data.photo_reference ? (
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        data.photo_reference
                                      }
                                      alt="img"
                                      className="w-100"
                                    />
                                  ) : (
                                    <img src={service_img} alt="img" className="w-100" />
                                  )}
                                  <figure className="text-center mt-5">
                                    <div className='list_box_text'>
                                      <h2>{data.name}</h2>
                                      <h5 className="pt-4">{data.address}</h5>
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            );
                          })}
                        </div>)
                      }
                  </div>
                )
              }

            </div>
          </div>
        </section>
        {isshow && <Footer />}
      </>
    )
  }
}

export default withRouter(CarWashProvider)