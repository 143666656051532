import React, { Component } from 'react'
import LoginService from '../../../services/auth'
import Logo from "../../../assets/image/logo.png";
import { toast } from 'react-toastify';
import firebase from '../../../firebase'
import { withRouter } from "react-router";
import OTPInput from "otp-input-react";
import AuthService from "../../../services/auth"

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      NumberInput: true,
      OtpSend: false,
      userId:'',
      phoneNo: "",
      phoneNoErr:'',
      otpcode:'',
      otpErr:'',
      submit:false,
      codeSubmit:false,
      isLoading:true,
      isshow: false,
    }
  }

  validate = () => {
    const errors = {
      phoneNoErr : null,
      otpErr:null
    }

    if (!this.state.phoneNo) {
      errors.phoneNoErr = "Phone number is required!"
    } else if (this.state.phoneNo.length<10) {
      errors.phoneNoErr = "Phone number must be atleast 10 numbers"
    } 
    else if (this.state.phoneNo.length>10) {
      errors.phoneNoErr = "Phone number cannot exceed more than 10 numbers"
    }

    if (!this.state.otpcode){
      errors.otpErr = "OTP is required!"
    }

    return errors
  }

  configureCaptcha = () => {
    if(!window.recaptchaVerifier){
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': () => {
          this.onSignInSubmit();
        },
        defaultCountry: "IN"
      });
    }
  }

  handleInputChanged(event) {
    this.setState({
      phoneNo: event.target.value,phoneNoErr:null
    });
  }

  // otp send
  handleChangeOTP = (otpcode) => this.setState({ otpcode ,otpErr:null });

  onSignInSubmit(e) {
    e.preventDefault()

    const errors = this.validate()
    if (errors.phoneNoErr) {
      this.setState({ ...errors });
    } else {
      console.log("data to be send", this.state);
    }

    this.configureCaptcha()
    const phoneNumber = "+91" + this.state.phoneNo;

    if (this.state.phoneNo.length === 10) {
      this.setState({submit:true})
    }

    if (this.state.phoneNo.length === 10) {
      const appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        this.setState({ NumberInput:false, OtpSend: true })
        console.log("OTP has been sent")
        toast.success('OTP has been sent')
      }).catch((error) => {
        console.log("SMS not sent", error)
        toast.error('SMS not sent',error)
        setTimeout(() => {
          this.props.history.push("/login");
        }, 500);
      });
    }
    
  }

  onSubmitOTP = (e) =>{
    e.preventDefault()
    const code = this.state.otpcode

    const errors = this.validate()
    if (errors.otpErr) {
      this.setState({ ...errors });
    } else {
      console.log("data to be send", this.state);
    }

    if (this.state.otpcode.length === 6) {
      this.setState({codeSubmit:true})
    }

    if (this.state.otpcode.length === 6) {
      window.confirmationResult.confirm(code).then((result) => {
      const accessToken = result.user._delegate.accessToken
      if (accessToken) {
        const phoneNo = this.state.phoneNo
        LoginService.login({phone: phoneNo})
        .then(response => {
              if (response.status === true) 
              {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", response.users._id);
                localStorage.setItem("phone", response.users.phone);
              
                AuthService.login({phone: response.users.phone})
                  .then(response =>{
                      this.setState({userId:response.users._id})
                  }).catch(err =>{
                    console.log(err);
                  })

                this.props.history.push("/");
                toast.success(response.message)
              }else{
                toast.error(response.message);
              }
        })
      }
    }).catch((error) => {
     console.log({OTP:error});
    });
    }
    
  }

  render() {
    return (
      <>
        <div className='login'>
          <div className='container'>
            <div className='login_main'>
              <div className='row justify-content-center'>
                <div className='col-md-6'>
                  <div className='login_form'>
                    <div className='text-center' >
                      <img src={Logo} height="40" alt="logo" />
                    </div>
                    {this.state.NumberInput &&
                      <div>
                        <h2>Login</h2>
                        <form onSubmit={(e)=>this.onSignInSubmit(e)}>
                          <div className="form-group mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Enter your Number</label>
                            <div id="sign-in-button"></div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1">+91</span>
                              <input type="number" name='phoneNo' className="form-control" placeholder='Mobile number' value={this.state.phoneNo}  onChange={this.handleInputChanged.bind(this)} />
                            </div>
                            <div style={{ fontSize: 15, color: "red" }}>
                              {this.state.phoneNoErr}
                            </div>
                          </div>
                          {
                            this.state.submit === true ? (
                              <>
                              <div className='d-flex justify-content-between'>
                                <button type="submit" disabled className="btn btn-primary">Submit</button>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> 
                              </div>
                              </>
                          ) : (
                            <button type="submit"  className="btn btn-primary">Submit</button>
                          )
                          }
                        </form>
                      </div>
                    }
                    {this.state.OtpSend &&
                      <div>
                        <h2>Enter OTP</h2>
                        <form onSubmit={this.onSubmitOTP}>
                        <div className="form-group mb-3">

                        <div className="signin_otp">
                          <OTPInput
                            value={this.state.otpcode}
                            onChange={this.handleChangeOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                        />
                        </div>
                        <div style={{ fontSize: 15, color: "red" }}>
                            {this.state.otpErr}
                        </div>
                        </div>
                        {
                            this.state.codeSubmit === true ? (
                              <>
                              <div className='d-flex justify-content-between'>
                                <button type="submit" disabled className="btn btn-primary">Submit</button>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> 
                              </div>
                              </>
                          ) : (
                            <button type="submit"  className="btn btn-primary">Submit</button>
                          )
                          }
                        </form>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Login)