import Call from "./Call";

const getOffers = async () => {
  let d = await Call({
    path: "api/users/getoffers",
    method: "GET",
  });
  return d;
};

const exportObject = {getOffers}

export default exportObject