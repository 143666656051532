import React, { Component } from "react";
import Footer from "../../template/Footer";
import RoutesTab from "../CarAccessories/route";
import car_tyre from "../../../assets/image/car_tyre.png";
import CarAccessoriesService from "../../../services/CarAccessories";
import { withRouter } from "react-router";
import ReactLoading from "react-loading";
import { Helmet, HelmetProvider } from "react-helmet-async";

export class index extends Component {
  constructor(props) {
    super(props);
    this.OpenDetails = this.OpenDetails.bind(this);

    this.state = {
      isLoading: true,
      isshow: false,
      Data: [],
      searchVal: "",
    };
  }

  componentDidMount() {
    this.get_CarTyre();
  }

  get_CarTyre() {
    try {
      CarAccessoriesService.Get_CarTyre().then((response) => {
        this.setState({ Data: response.data });
        setTimeout(() => {
          this.setState({ isLoading: false, isshow: true });
        }, 200);
      });
    } catch (error) {
      console.log(error);
    }
  }

  OpenDetails(DetailsData) {
    this.props.history.push({
      pathname: "/service-details",
      state: { DetailsData: DetailsData },
    });
  }

  filterNames = ({ name }) => {
    return (
      name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1
    );
  };

  render() {
    const { isshow, Data } = this.state;

    return (
      <>
        <RoutesTab />

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <div className="input-group">
                  <input className="form-control border-end-0 border rounded-pill" onChange={(e) => this.setState({searchVal: e.target.value})} value={this.state.searchVal} type="search" placeholder="search" id="example-search-input" />
              </div>
            </div>
          </div>
        </div>

        <HelmetProvider>
          <Helmet>
            <title>CarTyre</title>
          </Helmet>
        </HelmetProvider>

        <section className="sec_main" id="carservice">
          <div className="container">
            <div>
              <div className="sec_title">
                <h4>Car Tyre Provider</h4>
              </div>

              <div className="sec_subtitle">
                <p>
                  Find the best service packages for your car.Select from a wide
                  range of car services,from general service, accidental repair
                  to custom repairs,etc...
                </p>
              </div>
              {this.state.isLoading ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#053961"}
                  delay={"1"}
                  height={"20%"}
                  width={"6%"}
                  className="loader"
                />
              ) : (
                <div className="row">
                  {Data.filter(this.filterNames).map((data) => {
                    return (
                      <div className="col-md-6 col-lg-3" key={data._id}>
                        <div
                          className="list_box_cars_wash shadow-lg bg-body rounded"
                          onClick={() => this.OpenDetails(data)}
                        >
                          {data.photo_reference ? (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                data.photo_reference
                              }
                              alt="img"
                              className="w-100"
                            />
                          ) : (
                            <img src={car_tyre} alt="img" className="w-100" />
                          )}
                          <figure class="text-center mt-5">
                            <div className="list_box_text">
                              <h2>{data.name}</h2>
                              <h5 class="pt-4">{data.address}</h5>
                            </div>
                          </figure>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div></div>
            </div>
          </div>
        </section>
        {isshow && <Footer />}
      </>
    );
  }
}

export default withRouter(index);
