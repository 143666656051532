import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Auth from '../../services/CheckToauth'
import { toast } from 'react-toastify';
import { withRouter } from "react-router";
import AddTocartService from '../../services/AddTocart'

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: 'Login',
      count: '0'
    }
  }

  componentDidMount() {
    let checkAuth = Auth();
    if (checkAuth) {
      AddTocartService.CartList({ userId: localStorage.getItem('userId') })
        .then(response => {
          this.setState({ count: response.cartList.length })
        })
      this.setState({ isLoggedIn: "Logout" })
    } else {
      this.setState({ isLoggedIn: "Login" })
    }
  }


  login() {
    let checkAuth = Auth();
    if (checkAuth) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("userId");
      localStorage.removeItem("phone");
      localStorage.clear();
      
      toast.success("You have been successfully logged out !")
      this.setState({ isLoggedIn: "Login" })
      this.props.history.push("/");
    } else {
      this.props.history.push("/login");
    }
  }


  cartList() {
    let checkAuth = Auth();
    if (checkAuth) {
      // window.location.href="/cart";
      this.props.history.push("/cart");
    } else {
      this.props.history.push("/login");
      toast.error("login please")
    }
  }

  getProfile(){
    this.props.history.push("/profile");
  }

  render() {

    return (
      <div className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg sticky-top">
            <div className="container-fluid">
              <NavLink className="navbar-brand" to="/">
                <img src={Logo} height="60" alt="logo" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                <div className="header_links">
                  <ul className="navbar-nav me-auto align-items-center my-2 my-lg-0">
                    <li className="nav-item">
                      <NavLink exact className="nav-link" to={"/"}>
                        HOME
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={"/car-service"}>
                        CAR SERVICE
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={"/car-provider"}>
                        CAR BUY/SALE
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={"/car-wash"}>
                        CAR WASH
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={"/car-accesories"}>
                        CAR ACCESSORIES
                      </NavLink>
                    </li>
                    
                    {/* profile  */}
                    {localStorage.getItem("accessToken") &&
                    <li className="nav-item"> 
                    <span className="nav-link" onClick={() => this.getProfile()}>PROFILE</span>
                    </li>
                        }
                    <li className="nav-item">
                      <span className="nav-link" onClick={() => this.login()}>{this.state.isLoggedIn}</span>
                      {/* <NavLink className="nav-link" onClick={() => this.login()}>{this.state.isLoggedIn}</NavLink> */}
                    </li>

                    {/* <li className="nav-item">
                      <div className="cart_icon">
                        <FontAwesomeIcon icon={faCartShopping} onClick={() => this.cartList()} style={{ color: "#053961" }} />
                        <span> {localStorage.getItem("Count")} </span>
                      </div>
                    </li> */}

                    {localStorage.getItem("Count") ? ( 
                      <li className="nav-item">
                        <div className="cart_icon">
                          <FontAwesomeIcon icon={faCartShopping} onClick={() => this.cartList()} style={{ color: "#053961" }} />
                          <span> {localStorage.getItem("Count")} </span>
                        </div>
                      </li>
                    ) : (
                       <li className="nav-item">
                        <div className="cart_icon">
                          <FontAwesomeIcon icon={faCartShopping} onClick={() => this.cartList()} style={{ color: "#053961" }} />
                          {/* <span> {localStorage.getItem("Count")} </span> */}
                          <span>0</span>
                        </div>
                      </li>
                    )}

                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}
export default withRouter(Header)