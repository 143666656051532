import React from "react";
import { NavLink } from "react-router-dom";
import car from "../../assets/image/home/car_banner.png";
import wheel from "../../assets/image/wheel.png";
import Footer from "./Footer";

const Welcome = () => {
  return (
    <>
      <div className="home-main">
        <img src={car} alt="car" className="car_img" />

        <div className="circle_1">
          <div className="circle_inner">
            <img src={wheel} alt="wheel" className="w-100" />
            <NavLink className="menu_link" to={"/car-accesories"}>
              ACCESSORIES
            </NavLink>
          </div>
        </div>

        <div className="circle_2">
          <div className="circle_inner">
            <img src={wheel} alt="wheel" className="w-100" />
            <NavLink className="menu_link" to={"/car-provider"}>
              CAR SALE
            </NavLink>
          </div>
        </div>

        <div className="circle_3">
          <div className="circle_inner">
            <img src={wheel} alt="wheel" className="w-100" />
            <NavLink className="menu_link" to={"/car-provider"}>
              CAR BUY
            </NavLink>
          </div>
        </div>

        <div className="circle_4">
          <div className="circle_inner">
            <img src={wheel} alt="wheel" className="w-100" />
            <NavLink className="menu_link" to={"/car-wash"}>
              CAR WASH
            </NavLink>
          </div>
        </div>

        <div className="circle_5">
          <div className="circle_inner">
            <img src={wheel} alt="wheel" className="w-100" />
            <NavLink className="menu_link" to={"/car-service"}>
              CAR SERVICE
            </NavLink>
          </div>
        </div>
      </div>
      <div className="welcome_footer">
        <Footer />
      </div>
    </>
  );
};

export default Welcome;
