import React, { useEffect, useState } from 'react'
import HeaderComponent from "../../template/Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RoutesProfile from "../ProfilePage/route"
import MycarsService from "../../../services/cars"
import Services from "../../../services/Services";
import AuthService from "../../../services/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import {useDropzone} from 'react-dropzone';
import { Button, Form, Modal } from 'react-bootstrap';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position:'relative'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  
  const remove_btn ={
    position: 'absolute',
    top: '5px',
    right: '5px',
    padding: '0px 5px',
  }

const Mycars = (props) => {
    const id = localStorage.getItem("userId")
    const thisYear = (new Date()).getFullYear()
    const carNumberRef = React.useRef();
    const carSellPriceRef = React.useRef();
    const carKilometerRef = React.useRef();
    const descriptionRef = React.useRef();
    const [userData,setUserData] = useState('')
    const [cars,setCars] = useState()
    const [sell,notSell] = useState(false)
    const [selectManufacturee,SetSelectManufacturee] = useState([])
    const [fuel,setFuel] = useState([])
    const [manufacturer_ModelId_FuelType,SetManufacturer_ModelId_FuelType] = useState('')
    const [manufacturerId,setManufacturerId] = useState('')
    const [manufacturerModalId,setManufacturerModalId] = useState('')
    const [manufacturerYear,setManufacturerYear] = useState('')
    const [selectModel,setSelectModel] = useState([])
    const [car,setCar] = useState({
        userId:'',
        carOwnerName:'',
        carNumber:'',
        carPrice:'',
        carSellPrice:'',
        carKilometer:'',
        carDescription:'',
        manufacturerFuelId:'',
        manufacturerYear:'',
        type:"sell"
    })

    const [isLoading,setIsLoading] = useState(true)
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const [show, setShow] = useState(false);
    const [EditShow, setEditShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true)
    
    const [fileData,setFileData] = useState([''])
    const [editCars,setEditCars] = useState([])


    const {getRootProps, getInputProps} = useDropzone({
        maxFiles:6,
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: acceptedFiles => {
            setFileData(acceptedFiles.map((file,index) => Object.assign(file, {
                preview: URL.createObjectURL(file),
                id:index
            })));
        }
    })
  
    const remove = (key) =>{
        const deleteVal = [...fileData]
        deleteVal.splice(key,1)
        setFileData(deleteVal)
    }

    // const remove_old = (key) =>{
    //     const deleteVal = [...oldData]
    //     deleteVal.splice(key,1)
    //     setOldData(deleteVal)
    // }

    // if (fileData.length > 0) {
    //     const thumbs = fileData.map((file,key) => (
    //         <div style={thumb} key={file.name}>
    //           <div style={thumbInner}>
    //             <button 
    //                 style={remove_btn}
    //                 type="button" 
    //                 class="btn btn-danger"
    //                 onClick={()=>remove(key)}
    //             ><FontAwesomeIcon icon={faTimes} /></button>
    
    //             <img
    //               src={file.preview}
    //               style={img}
    //               onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //             />
    //           </div>
    //         </div>
    //     ));
    // }

    
    const SelectManufactureYear = [];
    let history = useHistory();

    for (let index = 0; index < 15; index++) {
      SelectManufactureYear.push(thisYear - index)      
    }

    useEffect(()=>{  
        MyCars()
        get_Manufacturee()
        get_FuelType()
        getuser()   
        
        return () => fileData.forEach(file => URL.revokeObjectURL(file.preview))
    },[])    

    const getuser = async () =>{
        await AuthService.login({phone: localStorage.getItem("phone")})
        .then(response =>{
            setUserData(response.users.name);
        })
    }

    const MyCars = async () => {
        await MycarsService.Usercars(id).then(res =>{
            setCars(res.result)
            setTimeout(() => {
                setIsLoading(false)
            }, 300);
        }).catch(err =>{
            console.log(err);
        })
    }

    const get_Manufacturee = async () => {
        await Services.Manufacturer().then((response) => {
            SetSelectManufacturee(response.result)
        }).catch(err =>{
            console.log(err);
        })
    }
    
    const ManufacturerId = async (id) =>{
        setManufacturerId(id)
        await Services.Model({id}).then((response)=>{
            setSelectModel(response.getModel)
        })
    }

    const get_FuelType = async (id) => {
        setManufacturerModalId(id)
        await Services.FuelType({id}).then(response => {
            setFuel(response.data)
        })
    }

    const EditClick = async (data) =>{
            setEditCars(data)
            setFileData(data.carImages)
            handleEditShow()
        
    }

    const handleChange = (e) => {
        setCar({...car,[e.target.name]:e.target.value})
    }

    const switchClick=()=>{
        notSell(!sell)
    }

    const deleteCars = (data) => {
        swal({
            title: "Are you sure?",
            text: "Are sure want to delete this car?'",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            }).then(willDelete =>{
                if (willDelete) {
                    MycarsService.deleteCars(data?._id)
                    .then((response) => {
                        swal({
                            title: "Done!",
                            text: "Car is deleted",
                            icon: "success",
                            timer: 1500,
                            showConfirmButton: false,
                            button: "Okay",
                        })
                        MyCars();
                    })
                }
            })
    }

    const handleCancel = () => {
       return( 
        setCar({
            carDescription:'',
            carKilometer:'',
            carNumber: '',
            carOwnerName:'',
            carPrice:'',
            carSellPrice:'',
            manufacturerFuelId:'',
            manufacturerYear:'',
            type:'',
            userId:'',
        })
        )
    }
        
    const CreateCar = async (e) =>{
        e.preventDefault();

        const errorMessages = validate(car,selectManufacturee,selectModel,fuel);
        if (Object.keys(errorMessages).length === 0) {
            const formData =  new FormData()
            formData.append("userId",id)
            formData.append("carOwnerName",userData );
            formData.append("carNumber", car.carNumber);
            formData.append("carKilometer", car.carKilometer);
            formData.append("description", car.carDescription);
            
            {
                car.carSellPrice ? (formData.append("carSellPrice", car.carSellPrice)) : (formData.append("carSellPrice", ''))
            }
            formData.append("manufacturerFuelId", manufacturer_ModelId_FuelType);
            {
                car.carSellPrice && (formData.append("type",'sell')) 
            }

            formData.append("manufacturerYear", manufacturerYear);
            
            if (fileData.length > 0) {
                for (let index = 0; index < fileData.length; index++) {
                    const element = fileData[index];
                    formData.append("image", element);
                }
            } else {
                formData.append("image", fileData);
            }

            await MycarsService.addCars(formData).then(response =>{
                if (response.status === true) {
                    swal({
                        title: "Success!",
                        text: response.message,
                        icon: "success",
                      });
                    handleClose()
                    handleCancel()
                    history.push("/mycars")
                    MyCars()
                } else {{
                    swal({
                        title: "oops!",
                        text: response.message,
                        icon: "error",
                      });
                }}
            }).catch(err =>{
                console.log(err);
            })
        }
        setIsSubmit(true);
    }   

    const validate = (values) => {
        const errors = {};
    
        if (!values.carNumber) {
          errors.carNumber = "carNumber is required!";
        }
        if (!manufacturer_ModelId_FuelType) {
            if (!values.selectManufacturee) {
                errors.selectManufacturee = "carManufacturee is required!";
            }
            if (!values.selectModel) {
                errors.selectModel = "carModal is required!";
            }
            if (!values.fuel) {
                errors.fuel = "carFuel is required!";
            }
        }
        if (sell) {
            if (!values.carSellPrice) {
                errors.carSellPrice = "carPrice is required!";
            }
            if (!values.carKilometer) {
                errors.carKilometer = "carKilometer is required!"
            }
        }
        setErrors(errors);
        return errors;
    };

    const updateCar = async (e) =>{
        e.preventDefault()

        const fmData = new FormData();
        fmData.append("id", editCars._id);

            if (car.carNumber.length === 0) {
                fmData.append("carNumber", editCars.carNumber);
            }else{
                fmData.append("carNumber", car.carNumber);
            }
            if (car.carSellPrice.length === 0) {
                fmData.append("carSellPrice", editCars.carSellPrice);
            }else{
                fmData.append("carSellPrice", car.carSellPrice);
            }
            if (car.carKilometer.length === 0) {
                fmData.append("carKilometer", editCars.carKilometer);
            }else{
                fmData.append("carKilometer", car.carKilometer);
            }
            if (car.carDescription === '') {
                fmData.append("description", editCars?.description);
            }else{
                fmData.append("description", car.carDescription);
            }
                
        fmData.append("manufacturerFuelId", editCars.manufacturerFuelId);
       
        
        if (fileData && fileData.length > 0) {
            for (let index = 0; index < fileData.length; index++) {
                const element = fileData[index];
                fmData.append("image", element);
            }
        }
     
        MycarsService.carUpdate(fmData).then(response => {
            if (response) {
                swal({
                    title: "Success!",
                    text: response.message,
                    icon: "success",
                    button:"okay",
                    button: MyCars(),
                });
                handleEditClose()
                handleCancel()
                setTimeout(() => {
                    MyCars()
                }, 500);
            } else {
                swal({
                    title: "oops!",
                    text: response.message,
                    icon: "error",
                });
            }
        })
    }

  return (
    <>
        <HeaderComponent />
            <HelmetProvider>
                <Helmet>
                    <title>Cars</title>
                </Helmet>
            </HelmetProvider>
             <div className="container mt-5">
                <div className='sec_title text-center'> <h4>My Cars</h4></div>
                <div className="sec_subtitle"> <p></p></div>
                    <div className="row">
                        <div className="col-12 mb-5">
                            <RoutesProfile />
                        </div>
                        <div className="col-12">
                            <div className="shadow p-3 mb-5 bg-body rounded justify-content-center">
                                <div className='text-end m-2'>
                                    <button data-bs-target="#exampleModalAdd" data-bs-toggle="modal" onClick={handleShow} className='btn btn-primary'><span>Add Car</span></button>
                                </div>
                                {
                                (cars) && <>
                                    {(isLoading) ? (<ReactLoading type={'spinningBubbles'} color={'#053961'} delay={'1'} height={'20%'} width={'6%'} className='loader' />) : (
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className='text-center'>
                                                    <th scope="col">No</th>
                                                    <th scope="col">Car Number</th>
                                                    <th scope="col">Sale Price</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cars?.map((data,i)=>{
                                                    return (
                                                        <tr key={i} className='text-center'>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{data.carNumber}</td>
                                                            {
                                                                (data.carSellPrice) ? (<td>{data.carSellPrice}</td>) : (<td>N/A</td>)
                                                            }
                                                            <td> 
                                                                <a type="button" data-bs-target="#exampleModal" data-bs-toggle="modal" className="" onClick={()=>EditClick(data)}><FontAwesomeIcon  color='blue' icon={faEdit} /></a>
                                                                <a className="ml-4" href="#" role="button" onClick={(e)=>deleteCars(data)}><FontAwesomeIcon color='red' icon={faTrash} /></a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    )} </>
                                }
                            </div>

                            {/* add Modal */}
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Car</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={CreateCar}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="form-label fw-bold">Car Number</Form.Label>
                                            <Form.Control type="text" style={{"textTransform":"uppercase"}} onChange={(e)=>handleChange(e)} name='carNumber' placeholder="carNumber"/>
                                            <span className="text-danger">{errors.carNumber}</span>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label className="form-label fw-bold">Car Manufacturer</Form.Label>
                                            <Form.Select name='selectManufacturee' onChange={(e)=>ManufacturerId(e.target.value)}>
                                                    <option selected> Select Manufacturer</option>
                                                    {
                                                        selectManufacturee.map((data) =>{
                                                            return <option key={data._id} value={data._id}>{data.brand_title}</option>
                                                        })
                                                    }
                                            </Form.Select>
                                            <span className="text-danger">{errors.selectManufacturee}</span>
                                        </Form.Group>

                                        {manufacturerId && 
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Modal</Form.Label>
                                                <Form.Select name='selectModel' onChange={(e)=>get_FuelType(e.target.value)}>
                                                    <option selected> Select Modal</option>
                                                        {
                                                            selectModel.map(data =>{
                                                                return <option key={data._id} value={data._id}>{data.title}</option>
                                                            })
                                                        }
                                                </Form.Select>
                                                <span className="text-danger">{errors.selectModel}</span>
                                            </Form.Group>
                                        }

                                        {manufacturerModalId && 
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Fuel</Form.Label>
                                                <Form.Select name='fuel' onChange={(e)=>SetManufacturer_ModelId_FuelType(e.target.value)} className="form-select" >
                                                    <option selected> Select Fuel</option>
                                                        {
                                                            fuel.map(data =>{
                                                                return <option key={data._id} value={data._id}>{data.title}</option>
                                                            })
                                                        }
                                                </Form.Select>
                                                <span className="text-danger">{errors.fuel}</span>
                                            </Form.Group>
                                        }

                                        {manufacturer_ModelId_FuelType &&
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Year</Form.Label>
                                                <Form.Select className="form-select" onChange={(e) =>  setManufacturerYear(e.target.value)} aria-label="Default select example">
                                                    <option selected> Select ManufacturerYear</option>
                                                    {
                                                        SelectManufactureYear.map((data)=>{
                                                            return <option key={data._id} value={data}>{data}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        }

                                                <div {...getRootProps({className: 'dropzone'})}>
                                                    <label htmlFor="name1" className="form-label fw-bold ">Car Images</label>
                                                    <input name='image' {...getInputProps()} />
                                                    <p className='dragimg'>Drag 'n' drop some files here, or click to select files</p>

                                                </div>
                                                {
                                                    // (fileData.length === '') && (
                                                        fileData.map((file,key) => (
                                                            <div style={thumb} key={file.name}>
                                                            <div style={thumbInner}>
                                                                <button 
                                                                    style={remove_btn}
                                                                    type="button" 
                                                                    class="btn btn-danger"
                                                                    onClick={()=>remove(key)}
                                                                ><FontAwesomeIcon icon={faTimes} /></button>
                                                    
                                                                <img
                                                                src={file.preview}
                                                                style={img}
                                                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                />
                                                            </div>
                                                            </div>
                                                        ))
                                                    // )
                                                }
                                                

                                        <div>
                                            <div className="form-check form-switch p-0 mt-3">
                                                <label className="form-check-label mr-5" htmlFor="flexSwitchCheckChecked">Are you want to sell your car ?</label>
                                                <input className="form-check-input" defaultChecked={sell}  onClick={switchClick} type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
                                            </div>
                                            <div>
                                                {(sell) && 
                                                <>
                                                       <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <p>What is the selling price of your car ?</p>
                                                            <Form.Label className="form-label fw-bold">Car Price</Form.Label>
                                                            <Form.Control type="number" onChange={(e)=>handleChange(e)} name='carSellPrice' placeholder='carPrice'/>
                                                            <span className="text-danger">{errors.carSellPrice}</span>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label className="form-label fw-bold">Car Kilometer</Form.Label>
                                                            <Form.Control type="number" onChange={(e)=>handleChange(e)}  name='carKilometer' className="form-control" placeholder="carKilometer"/>
                                                            <span className="text-danger">{errors.carKilometer}</span>

                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label className="form-label fw-bold">Car Description</Form.Label>
                                                            <Form.Control type="textarea" onChange={(e)=>handleChange(e)}  name='carDescription' className="form-control" placeholder="carDescription"/> 
                                                        </Form.Group>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className='text-end mt-5'>
                                            <Button variant="primary" className='m-1' type="submit">
                                                Submit
                                            </Button>
                                            <Button variant="danger" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                   
                                </Modal.Footer> */}
                            </Modal>

                            {/* Update Modal */}
                            <Modal show={EditShow} onHide={handleEditClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Car</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <Form onSubmit={updateCar}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="form-label fw-bold">Car Number</Form.Label>
                                            <Form.Control type="text" ref={carNumberRef} defaultValue={editCars?.carNumber} style={{"textTransform":"uppercase"}} onChange={(e)=>handleChange(e)} name='carNumber' placeholder="carNumber"/>
                                            <span className="text-danger">{errors.carNumber}</span>
                                        </Form.Group>

                                        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label className="form-label fw-bold">Car Manufacturer</Form.Label>
                                            <Form.Select name='selectManufacturee' onChange={(e)=>ManufacturerId(e.target.value)}>
                                                    <option selected> Select Manufacturer</option>
                                                    {
                                                        selectManufacturee.map((data) =>{
                                                            return <option key={data._id} value={data._id}>{data.brand_title}</option>
                                                        })
                                                    }
                                            </Form.Select>
                                            <span className="text-danger">{errors.selectManufacturee}</span>
                                        </Form.Group>

                                        {manufacturerId && 
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Modal</Form.Label>
                                                <Form.Select name='selectModel' onChange={(e)=>get_FuelType(e.target.value)}>
                                                    <option selected> Select Modal</option>
                                                        {
                                                            selectModel.map(data =>{
                                                                return <option key={data._id} value={data._id}>{data.title}</option>
                                                            })
                                                        }
                                                </Form.Select>
                                                <span className="text-danger">{errors.selectModel}</span>
                                            </Form.Group>
                                        }

                                        {manufacturerModalId && 
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Fuel</Form.Label>
                                                <Form.Select name='fuel' onChange={(e)=>SetManufacturer_ModelId_FuelType(e.target.value)} className="form-select" >
                                                    <option selected> Select Fuel</option>
                                                        {
                                                            fuel.map(data =>{
                                                                return <option key={data._id} value={data._id}>{data.title}</option>
                                                            })
                                                        }
                                                </Form.Select>
                                                <span className="text-danger">{errors.fuel}</span>
                                            </Form.Group>
                                        }

                                        {manufacturer_ModelId_FuelType &&
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="form-label fw-bold">Car Year</Form.Label>
                                                <Form.Select className="form-select" onChange={(e) =>  setManufacturerYear(e.target.value)} aria-label="Default select example">
                                                    <option selected> Select ManufacturerYear</option>
                                                    {
                                                        SelectManufactureYear.map((data)=>{
                                                            return <option key={data._id} value={data}>{data}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        } */}

                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <label htmlFor="name1" className="form-label fw-bold">Car Images</label>
                                                <input name='image' {...getInputProps()} />
                                                <p className='dragimg'>Drag 'n' drop some files here, or click to select files</p>

                                            </div>
                                            {
                                                (fileData) &&
                                                    (<>
                                                    <aside style={thumbsContainer}>
                                                        {fileData.map((file,key) =>{
                                                        return (
                                                            <div style={thumb} key={file.name}>
                                                                <div style={thumbInner}>
                                                                    {/* <button 
                                                                        style={remove_btn}
                                                                        type="button" 
                                                                        class="btn btn-danger"
                                                                        onClick={()=>remove(key)}
                                                                    ><FontAwesomeIcon icon={faTimes} /></button> */}
                                                                    <img
                                                                        src={process.env.REACT_APP_CARS_IMAGE + file}
                                                                        style={img}
                                                                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                    />
                                                                    {(file.preview)&&(
                                                                        <> <button 
                                                                        style={remove_btn}
                                                                        type="button" 
                                                                        class="btn btn-danger"
                                                                        onClick={()=>remove(key)}
                                                                    ><FontAwesomeIcon icon={faTimes} /></button> 
                                                                    <img
                                                                        src={file.preview}
                                                                        style={img}
                                                                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                    />
                                                                    </>)}
                                                                    
                                                                </div>
                                                            </div>)
                                                        })}
                                                    </aside>
                                                    </>
                                                    ) 
                                            }
                                        <div>
                                            <div className="form-check form-switch p-0">
                                                <label className="form-check-label mr-5" htmlFor="flexSwitchCheckChecked">Are you want to sell your car ?</label>
                                                <input className="form-check-input" defaultChecked={sell}  onClick={switchClick} type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
                                            </div>
                                            <div>
                                                {(editCars?.carSellPrice) ? (
                                                    <div>
                                                        {(!sell) && (
                                                            <>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <p>What is the selling price of your car ?</p>
                                                                <Form.Label className="form-label fw-bold">Car Price</Form.Label>
                                                                <Form.Control type="number" ref={carSellPriceRef} defaultValue={editCars?.carSellPrice} name='carSellPrice' className="form-control" onChange={(e)=>handleChange(e)} placeholder='carPrice'/>
                                                            </Form.Group>        
                                                                    <Form.Group>
                                                                    <Form.Label className="form-label fw-bold">Car Kilometer</Form.Label>
                                                                    <Form.Control type="number" ref={carKilometerRef} defaultValue={editCars?.carKilometer} onChange={(e)=>handleChange(e)}  name='carKilometer' className="form-control" placeholder="carKilometer"/>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label htmlFor="name1"  className="form-label fw-bold">Car Description</Form.Label>
                                                                    <Form.Control type="textarea" ref={descriptionRef} defaultValue={editCars?.description} onChange={(e)=>handleChange(e)}  name='carDescription' className="form-control" placeholder="carDescription"/>
                                                            </Form.Group>
                                                            </>
                                                        )}
                                                    </div>
                                                    ) : (
                                                    <div>
                                                        {(sell) && (
                                                        <>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <p>What is the selling price of your car ?</p>
                                                                <Form.Label className="form-label fw-bold">Car Price</Form.Label>
                                                                <Form.Control type="number" onChange={(e)=>handleChange(e)} name='carSellPrice' placeholder='carPrice'/>
                                                                <span className="text-danger">{errors.carPrice}</span>
                                                            </Form.Group>

                                                            <Form.Group>
                                                                <Form.Label className="form-label fw-bold">Car Kilometer</Form.Label>
                                                                <Form.Control type="number" onChange={(e)=>handleChange(e)}  name='carKilometer' className="form-control" placeholder="carKilometer"/>
                                                                <span className="text-danger">{errors.carKilometer}</span>

                                                            </Form.Group>

                                                            <Form.Group>
                                                                <Form.Label className="form-label fw-bold">Car Description</Form.Label>
                                                                <Form.Control type="textarea" onChange={(e)=>handleChange(e)}  name='carDescription' className="form-control" placeholder="carDescription"/> 
                                                            </Form.Group>
                                                        </>
                                                        )}
                                                    </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='text-end mt-5'>
                                            <Button variant="primary" className='m-1' type="submit">
                                                Submit
                                            </Button>
                                            <Button variant="danger" onClick={handleEditClose}>
                                                Close
                                            </Button>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
            </div>
    </>
  )
}

export default Mycars