/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Header from "../../template/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Services from "../../../services/Services";
import avtar from "../../../assets/image/car_provider/avatar.png";
import { useHistory } from "react-router-dom";

const CarProvider = () => {
  const [users, setUsers] = useState([]);
  const [CarPrices, setCarPrices] = useState([]);

  let history = useHistory();

  useEffect(() => {
    Users();
    CarBuyProviders();
  }, []);

  const CarBuyProviders = () => {
    Services.BuyProvider()
      .then((response) => {
        let carprice = [];
        response.cars.map((data) => {
          return <>{data.carSellPrice && carprice.push(data?.userId?._id)}</>;
        });
        setCarPrices(carprice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Users = () => {
    Services.UserProvider()
      .then((response) => {
        setUsers(response.usersAll);
        // this.setState({ UserProviders: response.usersAll });
        // setTimeout(() => {
        //   this.setState({ isLoading: false, isshow: true });
        // }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openDetails = (data) => {
    history.push({
      pathname: "/buy-details",
      state: { details: data },
    });
  };

  return (
    <>
      <Header />

      <HelmetProvider>
        <Helmet>
          <title>CarBuy/Sale</title>
        </Helmet>
      </HelmetProvider>

      <section className="sec_main" id="carprovider">
        <div className="container">
          <div className="car_provider">
            <div className="sec_title">
              <h4>Car Buy/Sale Provider</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                Planning to buy a used car or sell your old car? Here best
                provider is the best place to buy top certified used cars, Find
                the best valuation for your old car & sell at...
              </p>
            </div>
            <div className="row">
              {users.map((data, id) => {
                if (CarPrices.includes(data._id)) {
                  return (
                    <div className="col-md-6 col-lg-3" key={id}>
                      <div
                        className="list_box_cars_provider shadow-lg bg-body rounded"
                        onClick={() => openDetails(data)}
                      >
                        <img src={avtar} alt="img" className="w-100" />
                        <figure className="text-center mt-3">
                          <div className="list_box_text">
                            <h2>{data?.name}</h2>
                            <h5 className="pt-4">{data?.address}</h5>
                          </div>
                        </figure>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarProvider;
