import firebase from "firebase/compat/app";
import "firebase/compat/auth";

var firebaseConfig = {
  apiKey: "AIzaSyC5LZcDZwkN906XoENYviizxINT9K7ZnL4",
  authDomain: "urbankarsserviceprovider.firebaseapp.com",
  projectId: "urbankarsserviceprovider",
  storageBucket: "urbankarsserviceprovider.appspot.com",
  messagingSenderId: "866819632210",
  appId: "1:866819632210:web:87a8a1e8cf42516e20c38b",
  measurementId: "G-TQVBN588L5",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
