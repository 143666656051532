import React, { Component } from 'react'
// import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CarsService from "./views/pages/CarsService";
import Home from "./views/home/Home"
import CarProvider from "./views/pages/CarProvider";
import CarWashProvider from "./views/pages/CarWashProvider";
import CarAccessories from "./views/pages/CarAccessories";
import PrivateRoute from "../src/views/pages/Routes/PrivateRoute";
import PublicRoute from "../src/views/pages/Routes/PublicRoute";
import Main from "./views/Main";
import ProfilePage from "./views/pages/ProfilePage";
import ServiceDetails from "./views/pages/Service_Details";
import CarBattery from "./views/pages/Car_Battery";
import CarTyre from "./views/pages/CarTyre";
import OrderList from "./views/pages/OrderList";
import Welcome from "./views/template/Welcome";
import Cart from "./views/pages/Cart";
import Mycars from "./views/pages/Mycars";
import LoginComponent from "./views/pages/login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProviderSuccess from './views/pages/MessagePage/ProviderSuccess';
import MyBookings from './views/pages/ProfilePage/MyBookings';
import Buynow_cart from './views/pages/Cart/buynow_cart';
import BuyDetail from './views/pages/CarProvider/service_details';
import CarDetails from './views/pages/CarProvider/car_details';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      AccessToken: ''
    }
  }

  componentDidMount() {
    let token = localStorage.getItem('isToken')
    if (token) {
      this.setState("token")
    }
  }

  render() {
    return (
      <>
        <div className="App">

          <Router>
            <Switch>

            <Route exact path="/">
                <Home />
              </Route>

              <Route path="/welcome">
                <Welcome />
              </Route>

              {/* <Route path="/home">
                <Home />
              </Route> */}

              <Route path="/car-service">
                <CarsService />
              </Route>

              <Route path="/car-provider">
                <CarProvider />
              </Route>

              <Route path="/car-wash">
                <CarWashProvider />
              </Route>

              <Route path="/provider-success/:verify/:id">
                <ProviderSuccess />
              </Route>

              <Route path="/car-accesories">
                <CarAccessories />
              </Route>

              <PrivateRoute 
              path="/cart" 
              component={Cart} 
              />
              
              <PrivateRoute path="/service-cart" 
              component={Buynow_cart} 
              />

              <PrivateRoute 
              component={ProfilePage} 
              path="/profile" 
              />

              <PrivateRoute 
              path="/myorder" 
              component={OrderList} 
              />

              <PrivateRoute 
              path="/mybookings" 
              component={MyBookings} 
              />

            <PrivateRoute 
              path="/mycars" 
              component={Mycars} 
              />

            <PublicRoute 
              path="/service-details" 
              component={ServiceDetails} 
              />

            <PublicRoute 
              path="/buy-details" 
              component={BuyDetail} 
              />

            <PublicRoute 
              path="/car-details" 
              component={CarDetails} 
              />

            <PublicRoute 
              path="/car-battery" 
              component={CarBattery} 
              />
              
              <PublicRoute 
              path="/car-tyre" 
              component={CarTyre} 
              />

              {/* <PublicRoute  path="*"  component={ <Error} /> */}

              <PublicRoute
              restricted={true}
              component={LoginComponent}
              path="/login"
              exact
            />

            </Switch>
          </Router>
        </div>

        <ToastContainer
          autoClose={1000}
          hideProgressBar={true}
        />

      </>
    )
  }
}

export default App