import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import "./index.css"

export class route extends Component {
    render() {
        return (
            <>
            
                <nav className="nav nav-pills flex-row">
                    <NavLink role="button" className="btn btn-primary nav-link text-left fw-bold" to="/profile">Profile</NavLink>
                    <NavLink className="btn btn-primary nav-link text-left ms-3 fw-bold" to="/myorder">My Order</NavLink>
                    <NavLink className="btn btn-primary nav-link text-left ms-3 fw-bold" to="/mybookings">My Bookings</NavLink>
                    <NavLink className="btn btn-primary nav-link text-left ms-3 fw-bold" to="/mycars">My Car</NavLink>
                </nav>
            </>
        )
    }
}

export default route