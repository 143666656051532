import React, { Component, useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = { width: "100%", height: "300px" };

const CarMap = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBsf7LHMQFIeuA_7-bR7u7EXz5CUaD6I2A",
  });

  const center = {
    lat: Number(props.lat),
    lng: Number(props.lng),
  };
  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: center?.lat, lng: center?.lng }}
          zoom={19}
        >
          <Marker
            text="My Marker"
            position={{ lat: center?.lat, lng: center?.lng }}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default CarMap;
