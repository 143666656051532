import Call from "./Call";

const servicesProvider = async () => {
  let d = await Call({
    path: "api/admin/servicesProvider",
    method: "GET",
  });
  return d;
};


const washProvider = async () => {
  let d = await Call({
    path: "api/admin/carwasherProviders",
    method: "GET",
  });
  return d;
};

// const BuyProvider = async () => {
//   let d = await Call({
//     path: "api/admin/carbuyerProvider",
//     method: "GET",
//   });
//   return d;
// };

const BuyProvider = async () => {
  let d = await Call({
    path: "api/admin/carsList",
    method: "GET",
  });
  return d;
};

const Usercars = async (data) =>{
  
  let d = await Call({
    path: "api/admin/userEdit",
    method: "post",
    data
  });
  return d;
}

const carsDetail = async (data) =>{
  
  let d = await Call({
    path: "api/admin/getModal",
    method: "post",
    data
  });
  return d;
}

const UserProvider = async () => {
  let d = await Call({
    path: "api/admin/userList",
    method: "GET",
  });
  return d;
};

const Service_Details = async (data) => {
  let d = await Call({
    path: "api/user/service-details",
    method: "POST",
    data,
  });
  return d;
};

const Manufacturer = async () => {
  let d = await Call({
    path: "api/admin/manufacturer",
    method: "GET",
  });
  return d;
};

const Model = async (id) => {
  let d = await Call({
    path: "api/admin/getmodeldata",
    method: "POST",
    data:id
  });
  return d;
};

const FuelType = async (id) => {
  let d = await Call({
    path: "api/admin/getfueldata",
    method: "POST",
    data:id
  });
  return d;
};

const SeletedCarService = async (id) => {
  let d = await Call({
    path: "api/user/carserviceProviders-details",
    method: "POST",
    data:id
  });
  return d;
};

const Buynow = async (data) => {
  let d = await Call({
    path: "api/user/buy_now",
    method: "POST",
    data
  });
  return d;
}

const VerifyServiceAPI = async (data) => {
  let d = await Call({
    path: "api/user/verify_service",
    method: "POST",
    data
  });
  return d;
}

const bookings = async (data) => {
  let d = await Call({
    path: "api/user/user_bookings",
    method: "POST",
    data
  });
  return d;
}

const bookingDelete = async (data) => {
  let d = await Call({
    path: "api/user/bookings_delete",
    method: "DELETE",
    data
  });
  return d;
}

const exportObject = { carsDetail,Usercars,UserProvider,servicesProvider,washProvider,BuyProvider,Service_Details,Manufacturer,Model,FuelType,SeletedCarService,Buynow,VerifyServiceAPI,bookings,bookingDelete}

export default exportObject