import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react'

const containerStyle = { width: "100%", height: "300px" };

const Map = (props) => {

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBsf7LHMQFIeuA_7-bR7u7EXz5CUaD6I2A",
  });

  const [center, setCenter] = useState({
    lat: Number(props.data.lat),
    lng: Number(props.data.lng),
  });

  return (
    <div>
        {isLoaded &&
          (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: center?.lat, lng: center?.lng }}
              zoom={19}
            >
              <Marker
                text="My Marker"
                position={{ lat: center?.lat, lng: center?.lng }}
              />
            </GoogleMap>
          )}
    </div>
  )
}
export default Map
